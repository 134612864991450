import { AuthWrapper } from '@ifca-ui/neumorphism'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import AppLogo from 'assets/images/hotelx.png'
import AuthBackground from 'assets/images/auth-background.jpg'
import { ReactComponent as CheckBoxIcon } from 'assets/svg/CheckBoxIcon.svg'
// import AppContext, { AppContextProps } from "containers/Context/Context";
import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  useGaInHouseGuestListingLazyQuery,
  useGuestLoginMutation,
  useGuestRegisterMutation,
  useSendRegisterOtpMutation,
  useVerifyOtpMutation,
} from '../../../generated/graphql'
import { PUBLIC_PATH } from './Routes'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import * as yup from 'yup'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { BOOKING_PATH } from '../Booking/Routes'
import { SERVICE_PATH } from '../Services/Routes'
import PhoneInput from 'react-phone-input-2'
import { OTPDialog } from './OTPDialog'
import { usePhoneValidation } from 'containers/hooks/usePhoneValidation'

interface RegisterProps {
  FullName?: string
  Login?: string
  Email?: string
  MobileNo?: string
  Password?: string
  ConfirmPassword?: string
}
export const RegisterSchema = yup.object().shape({
  FullName: yup.string().required('Full Name is required'),
  Login: yup.string().required('Login is required'),
  Email: yup.string().email().required('Email is required'),
  Password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase character'
    )
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase character'
    )
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(
      /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
      'Password must contain at least one symbol'
    ),
  ConfirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('Password'), null], 'Confirm password does not match'),
})
export const Register: FC<RegisterProps> = () => {
  const history = useHistory()
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const { isValidPoneNumber, validatePhonePrefix } = usePhoneValidation()
  const [CheckInHouseList] = useGaInHouseGuestListingLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.GAInHouseGuestListing?.length === 0) {
        history.push(BOOKING_PATH.BOOKING_LIST)
      } else {
        history.push(SERVICE_PATH.LIST)
      }
      RootDispatch({
        type: 'CloseSnackBar',
        payload: {},
      })
    },
  })
  const [OTPOpen, setOTPOpen] = useState(false)
  const [OTPVerifed, setOTPVerifed] = useState(false)
  const [OTP, setOTP] = useState('')
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<RegisterProps>({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(RegisterSchema),
  })
  const [GuestRegister, { loading }] = useGuestRegisterMutation({
    onError: error => {
      // eslint-disable-next-line array-callback-return
    },
    onCompleted: data => {
      if (data.GuestRegister) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Register Successfully.',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(PUBLIC_PATH.LOGIN)
        }, 2000)
      }
    },
  })
  const [sendRegisterOtpQuery] = useSendRegisterOtpMutation({
    fetchPolicy: 'no-cache',
    // onCompleted: data => {
    //   if (data?.sendRegisterOTP) {

    //   }
    // },
    // onError: err => {
    //   setOTPVerifed(false)
    //   RootDispatch({
    //     type: 'OnSnackBar',
    //     payload: {
    //       ...RootState.OnSnackBar,
    //       Open: true,
    //       Message: err?.graphQLErrors[0]?.message,
    //       onClick: () =>
    //         RootDispatch({
    //           type: 'CloseSnackBar',
    //           payload: {},
    //         }),
    //     },
    //   })
    // },
  })
  const [verifyOtpMutation] = useVerifyOtpMutation({
    fetchPolicy: 'no-cache',
    onError: error => {
      setOTP('')
      setOTPVerifed(false)

      RootDispatch({
        type: 'OnSnackBar',
        payload: {
          ...RootState.OnSnackBar,
          Open: true,
          Message: `${error?.graphQLErrors[0]?.message}, please resend again.`,
          onClick: () =>
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            }),
        },
      })
    },
    onCompleted: data => {
      if (data.verifyOTP) {
        setOTPOpen(false)
        setOTPVerifed(true)
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: `OTP verifed Successfully.`,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
  })

  console.log(watch('MobileNo'), 'here')
  useEffect(() => {
    if (watch('MobileNo') && OTP.length === 4) {
      verifyOtpMutation({
        variables: {
          MobileNo: validatePhonePrefix(watch('MobileNo')),
          OtpCode: OTP,
          OtpType: 'REGISTRATION',
        },
      })
    }
  }, [OTP])
  console.log(OTP, 'here')
  const onSubmit = data => {
    GuestRegister({
      variables: {
        Email: data.Email.toLowerCase(),
        MobileNo: validatePhonePrefix(data.MobileNo),
        FullName: data.FullName,
        Login: data.Login,
        Password: data.Password,
      },
    })
  }
  useEffect(() => {
    RootDispatch({
      type: 'OnLoading',
      payload: loading,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  const sendOTPRequest = () => {
    sendRegisterOtpQuery({
      variables: {
        OtpType: 'REGISTRATION',
        MobileNo: validatePhonePrefix(watch('MobileNo')),
      },
    })
      .then(i => {
        if (i.data.sendRegisterOTP) {
          setOTP('')
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: `A New OTP has resent to the registered mobile number.`,
              onClick: () =>
                RootDispatch({
                  type: 'CloseSnackBar',
                  payload: {},
                }),
            },
          })
        }
      })
      .catch(err => {
        err.graphQLErrors.map(({ message }) => {
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: message,
              onClick: () =>
                RootDispatch({
                  type: 'CloseSnackBar',
                  payload: {},
                }),
            },
          })
        })
      })
  }
  const handleOTPRequest = () => {
    if (isValidPoneNumber(validatePhonePrefix(watch('MobileNo')))) {
      sendRegisterOtpQuery({
        variables: {
          OtpType: 'REGISTRATION',
          MobileNo: validatePhonePrefix(watch('MobileNo')),
        },
      })
        .then(i => {
          if (i.data.sendRegisterOTP) {
            setOTPOpen(true)
            setOTP('')
          }
        })
        .catch(err => {
          err.graphQLErrors.map(({ message }) => {
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: message,
                onClick: () =>
                  RootDispatch({
                    type: 'CloseSnackBar',
                    payload: {},
                  }),
              },
            })
          })
        })
    } else {
      RootDispatch({
        type: 'OnSnackBar',
        payload: {
          ...RootState.OnSnackBar,
          Open: true,
          Message: 'Invalid Mobile No.',
          onClick: () =>
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            }),
        },
      })
    }
  }
  return (
    <AuthWrapper
      bgImageURL={AuthBackground}
      appLogoURL={AppLogo}
      title="Register"
      //   subTitle="Login ID and Password has been sent to your registered email address 7
      // days prior to Arrival Date. Please check your email to Login."
    >
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <div className="form-wrapper">
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Full Name (as per NRIC)"
                margin="dense"
                fullWidth
                helperText={errors?.FullName?.message}
                error={errors?.FullName ? true : false}
                type="text"
              />
            )}
            control={control}
            name="FullName"
          />
          <Box display="flex" width="100%">
            <Box width="60%" mb="8px" pr="4px">
              <Controller
                render={({ field }) => (
                  <PhoneInput
                    containerClass="contact-input-new"
                    inputProps={{
                      name: 'Mobile No.',
                      required: true,
                    }}
                    specialLabel="Mobile No. *"
                    {...field}
                    country={'my'} //Country prefix
                  />
                )}
                control={control}
                name="MobileNo"
              />
            </Box>
            <Box
              width="40%"
              mb="8px"
              pl="4px"
              display="flex"
              alignItems="center"
            >
              <Button
                variant="contained"
                className="otp-button"
                color="primary"
                size="small"
                fullWidth
                disabled={
                  !isValidPoneNumber(validatePhonePrefix(getValues('MobileNo')))
                }
                onClick={() => handleOTPRequest()}
              >
                Request OTP
              </Button>
            </Box>
          </Box>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Login ID"
                margin="dense"
                fullWidth
                helperText={errors?.Login?.message}
                error={errors?.Login ? true : false}
                type="text"
              />
            )}
            control={control}
            name="Login"
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                margin="dense"
                fullWidth
                helperText={errors?.Email?.message}
                error={errors?.Email ? true : false}
                type="email"
              />
            )}
            control={control}
            name="Email"
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                margin="dense"
                fullWidth
                helperText={errors?.Password?.message}
                error={errors?.Password ? true : false}
                type="password"
              />
            )}
            control={control}
            name="Password"
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Confirm Password"
                margin="dense"
                fullWidth
                helperText={errors?.ConfirmPassword?.message}
                error={errors?.ConfirmPassword ? true : false}
                type="password"
              />
            )}
            control={control}
            name="ConfirmPassword"
          />
          <div className="text-align-center">
            <span className="desc m-r-4">Do you have HotelX account?</span>
            <span
              className="desc color-orange click-text"
              onClick={() => history.push(PUBLIC_PATH.LOGIN)}
            >
              Try login
            </span>
          </div>
          <Box mt="6px" className="register-box">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="register-button"
              fullWidth
              disabled={!OTPVerifed}
            >
              Register
            </Button>
          </Box>
        </div>

        {/* <div className="button-box">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="login-btn"
            fullWidth
            disabled={!OTPVerifed}
          >
            Register
          </Button>
        </div> */}
      </form>
      <OTPDialog
        OTPOpen={OTPOpen}
        setOTPOpen={setOTPOpen}
        OTP={OTP}
        setOTP={setOTP}
        sendOTPRequest={sendOTPRequest}
        MobileNo={validatePhonePrefix(watch('MobileNo'))}
      />
    </AuthWrapper>
  )
}
