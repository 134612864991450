import { yupResolver } from '@hookform/resolvers/yup'
import { AuthWrapper } from '@ifca-ui/neumorphism'
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Box,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import AppLogo from 'assets/images/hotelx.png'
import AuthBackground from 'assets/images/auth-background.jpg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import jwtDecode from 'jwt-decode'
// import AppContext, { AppContextProps } from "containers/Context/Context";
import React, { FC, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'
import {
  useGuestResetPasswordMutation,
  useSendRegisterOtpMutation,
  useVerifyOtpMutation,
} from '../../../generated/graphql'
import { PUBLIC_PATH } from './Routes'
import { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { usePhoneValidation } from 'containers/hooks/usePhoneValidation'
import { OTPDialog } from './OTPDialog'

type RPProps = {
  NewPassword: string
  ConfirmPassword: string
  MobileNo?: string

  ShowNewPassword?: boolean
  ShowConfirmPassword?: boolean
}

const ResetPasswordSchema = yup.object().shape({
  NewPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase character'
    )
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase character'
    )
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(
      /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
      'Password must contain at least one symbol'
    ),
  ConfirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('NewPassword'), null], 'Confirm password does not match'),
})
export const ForgotPassword: FC = () => {
  const history = useHistory()

  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const [OTPOpen, setOTPOpen] = useState(false)
  const [OTPVerifed, setOTPVerifed] = useState(false)
  const [OTP, setOTP] = useState('')

  const { isValidPoneNumber, validatePhonePrefix } = usePhoneValidation()

  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RPProps>({
    defaultValues: {},
    mode: 'onBlur',
    resolver: yupResolver(ResetPasswordSchema),
  })
  const [GuestResetPassword, { loading }] = useGuestResetPasswordMutation({
    variables: {
      password: watch('NewPassword'),
      MobileNo: `+${watch('MobileNo')}`,
      //   token: token,
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.GuestResetPassword) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Password has been reset Successfully. Please Login Again',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(PUBLIC_PATH.LOGIN)
        }, 2000)
      }
    },
  })
  useEffect(() => {
    RootDispatch({
      type: 'OnLoading',
      payload: loading,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  const [sendRegisterOtpQuery] = useSendRegisterOtpMutation({
    fetchPolicy: 'no-cache',
  })
  const [verifyOtpMutation] = useVerifyOtpMutation({
    fetchPolicy: 'no-cache',
    onError: error => {
      setOTP('')
      setOTPVerifed(false)

      RootDispatch({
        type: 'OnSnackBar',
        payload: {
          ...RootState.OnSnackBar,
          Open: true,
          Message: `${error?.graphQLErrors[0]?.message}, please resend again.`,
          onClick: () =>
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            }),
        },
      })
    },
    onCompleted: data => {
      if (data.verifyOTP) {
        setOTPOpen(false)
        setOTPVerifed(true)
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: `OTP verifed Successfully.`,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
  })

  console.log(watch('MobileNo'), 'here')
  useEffect(() => {
    if (watch('MobileNo') && OTP.length === 4) {
      verifyOtpMutation({
        variables: {
          MobileNo: validatePhonePrefix(watch('MobileNo')),
          OtpCode: OTP,
          OtpType: 'FORGOT_PASSWORD',
        },
      })
    }
  }, [OTP])
  const sendOTPRequest = () => {
    sendRegisterOtpQuery({
      variables: {
        OtpType: 'FORGOT_PASSWORD',

        MobileNo: validatePhonePrefix(watch('MobileNo')),
      },
    })
      .then(i => {
        if (i.data.sendRegisterOTP) {
          setOTP('')
        }
      })
      .catch(err => {
        err.graphQLErrors.map(({ message }) => {
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: message,
              onClick: () =>
                RootDispatch({
                  type: 'CloseSnackBar',
                  payload: {},
                }),
            },
          })
        })
      })
  }
  const handleOTPRequest = () => {
    if (isValidPoneNumber(validatePhonePrefix(watch('MobileNo')))) {
      sendRegisterOtpQuery({
        variables: {
          OtpType: 'FORGOT_PASSWORD',
          MobileNo: validatePhonePrefix(watch('MobileNo')),
        },
      })
        .then(i => {
          if (i.data.sendRegisterOTP) {
            setOTPOpen(true)
            setOTP('')
          }
        })
        .catch(err => {
          err.graphQLErrors.map(({ message }) => {
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: message,
                onClick: () =>
                  RootDispatch({
                    type: 'CloseSnackBar',
                    payload: {},
                  }),
              },
            })
          })
        })
    } else {
      RootDispatch({
        type: 'OnSnackBar',
        payload: {
          ...RootState.OnSnackBar,
          Open: true,
          Message: 'Invalid Mobile No.',
          onClick: () =>
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            }),
        },
      })
    }
  }
  return (
    <>
      {' '}
      <AuthWrapper
        bgImageURL={AuthBackground}
        appLogoURL={AppLogo}
        title="Forgot Password"
      >
        <div className="form-wrapper">
          <Box display="flex" width="100%">
            <Box width="60%" mb="8px" pr="4px">
              <Controller
                render={({ field }) => (
                  <PhoneInput
                    containerClass="contact-input-new"
                    inputProps={{
                      name: 'Mobile No.',
                      required: true,
                    }}
                    specialLabel="Mobile No. *"
                    {...field}
                    country={'my'} //Country prefix
                  />
                )}
                control={control}
                name="MobileNo"
              />
            </Box>
            <Box
              width="40%"
              mb="8px"
              pl="4px"
              display="flex"
              alignItems="center"
            >
              <Button
                variant="contained"
                className="otp-button"
                color="primary"
                size="small"
                fullWidth
                disabled={
                  !isValidPoneNumber(validatePhonePrefix(getValues('MobileNo')))
                }
                onClick={() => handleOTPRequest()}
              >
                Request OTP
              </Button>
            </Box>
          </Box>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue(
                            'ShowNewPassword',
                            !getValues('ShowNewPassword')
                          )
                        }
                      >
                        {watch('ShowNewPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.NewPassword?.message}
                error={errors?.NewPassword ? true : false}
                autoComplete="off"
                fullWidth
                label="New Password"
                type={watch('ShowNewPassword') ? 'text' : 'password'}
              />
            )}
            name="NewPassword"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue(
                            'ShowConfirmPassword',
                            !getValues('ShowConfirmPassword')
                          )
                        }
                      >
                        {watch('ShowConfirmPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.ConfirmPassword?.message}
                error={errors?.ConfirmPassword ? true : false}
                autoComplete="off"
                fullWidth
                label="Repeat New Password"
                type={watch('ShowConfirmPassword') ? 'text' : 'password'}
              />
            )}
            name="ConfirmPassword"
            control={control}
          />
          <div className="text-align-center">
            <span
              className="desc color-orange click-text"
              onClick={() => history.push(PUBLIC_PATH.LOGIN)}
            >
              Back to Login
            </span>
          </div>
        </div>
        <div className="button-box">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="login-btn"
            fullWidth
            disabled={!OTPVerifed}
            onClick={() => GuestResetPassword()}
          >
            Save
          </Button>
        </div>
        <OTPDialog
          OTPOpen={OTPOpen}
          setOTPOpen={setOTPOpen}
          OTP={OTP}
          setOTP={setOTP}
          sendOTPRequest={sendOTPRequest}
          MobileNo={validatePhonePrefix(watch('MobileNo'))}
        />
      </AuthWrapper>
    </>
  )
}
