import { ContentWrapper } from '@ifca-ui/neumorphism'
import {
  Avatar,
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import DefaultImg from 'assets/images/default-img.png'
import StarIcon from 'assets/svg/booking/rating-star.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { amtStr } from 'containers/Utils/numFormatter'
import format from 'date-fns/format'
import { useHotelsByCityQuery } from 'generated/graphql'
import React, { FC, useContext, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useLocation } from 'react-router'
import { BOOKING_PATH } from '../Routes'
import SkeletonList from 'components/Skeleton/SkeletonList'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

type HotelListProps = {}
export const HotelList: FC<HotelListProps> = () => {
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  const {
    fetchMore,
    loading,
    data: { HotelsByCity } = { HotelsByCity: [] },
  } = useHotelsByCityQuery({
    // fetchPolicy: 'no-cache',

    variables: {
      // offset: 0,
      // limit: 10,
      City: localState?.Search || '',
      CheckIn: localState?.CheckInDate,
      CheckOut: localState?.CheckOutDate,
      NoOfRooms: Number(localState?.Rooms),
    },
  })
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Hotels',
          props: {
            onClick: () => history.push(BOOKING_PATH.BOOKING_LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {/* {loading && <Spinner />} */}

      <div className="chip-sticky">
        <div className="chip-container">
          {localState?.Search && <Chip label={localState?.Search} />}
          <Chip
            label={`  ${format(
              new Date(localState?.CheckInDate),
              'dd MMM yyyy'
            )} -
              ${format(new Date(localState?.CheckOutDate), 'dd MMM yyyy')}`}
          />
          <Chip
            label={`${localState.Rooms} Room, ${localState.Adults} Adults`}
          />
        </div>
      </div>
      <ContentWrapper subHeader>
        <List className="core-list ">
          {/* <InfiniteScroll
            dataLength={HotelsByCity?.length}
            next={() =>
              fetchMore({
                variables: {
                  offset: HotelsByCity?.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  console.log(prev, 'prev')
                  console.log(fetchMoreResult, 'fetchMoreResult')
                  return Object.assign({}, prev, {
                    HotelsByCity: [
                      ...prev.HotelsByCity,
                      ...fetchMoreResult.HotelsByCity,
                    ],
                  })
                },
              })
            }
            hasMore={true}
            // height={700}
            className="rm-padding"
            style={{ padding: '10px 12px' }}
            loader={loading && <SkeletonList type="Hotel" />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          > */}
          {loading ? <SkeletonList type="Hotel" /> : null}
          {HotelsByCity?.map((i, index) => (
            <ListItem
              key={index}
              className="hotel"
              onClick={() =>
                history.push('/booking/hotel/detail', {
                  HotelDetail: i,
                  // ...localState,
                  Search: localState.Search,
                  CheckInDate: localState.CheckInDate,
                  CheckOutDate: localState.CheckOutDate,
                  Rooms: localState.Rooms,
                  Adults: localState.Adults,
                })
              }
            >
              <ListItemAvatar>
                <Avatar
                  variant="rounded"
                  alt={i?.HotelName}
                  src={
                    (i?.HotelGallery &&
                      i?.HotelGallery[0]?.Gallery?.ImageURL) ||
                    DefaultImg
                  }
                />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <span className="xsTitle fw-500 flex-space">
                      {i?.HotelName}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc text m-b-4">
                      {i.HotelAddress?.address}, {i.HotelAddress?.city},{' '}
                      {i.HotelAddress?.state}
                    </span>
                    <span
                      className="flex-width "
                      style={{
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className="icon-text desc rating">
                        <img src={StarIcon} alt={'star'} />{' '}
                        {amtStr(i.WeightedAverage)}
                      </div>

                      <span className="fs-14 fw-bold icon-text">
                        <MonetizationOnIcon />
                        {amtStr(i.Price[0]?.Price)}
                        <span className="fs-14 fw-regular">/night</span>
                      </span>
                    </span>
                  </>
                }
              />
            </ListItem>
          ))}
          {/* </InfiniteScroll> */}
        </List>
      </ContentWrapper>
    </>
  )
}
