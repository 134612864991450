import React, { lazy } from 'react'
const MyProfile = lazy(() =>
  import('./MyProfile').then(module => ({
    default: module.MyProfile,
  }))
)
const AboutUs = lazy(() =>
  import('./AboutUs').then(module => ({
    default: module.AboutUs,
  }))
)
const ChangePassword = lazy(() =>
  import('./ChangePassword').then(module => ({
    default: module.ChangePassword,
  }))
)
const OCRTest = lazy(() =>
  import('./OCRTest').then(module => ({
    default: module.OCRTest,
  }))
)
export const ACCOUNT_PATH = {
  PROFILE: '/account/profile',
  ABOUT_US: '/account/about-us',
  CHANGE_PASSWORD: '/account/change-password',
  OCR: '/account/ocr-test',
}
const AccountRoutes = [
  {
    props: { exact: true, path: ACCOUNT_PATH.PROFILE },
    component: <MyProfile />,
  },
  {
    props: { exact: true, path: ACCOUNT_PATH.ABOUT_US },
    component: <AboutUs />,
  },
  {
    props: { exact: true, path: ACCOUNT_PATH.CHANGE_PASSWORD },
    component: <ChangePassword />,
  },
  {
    props: { exact: true, path: ACCOUNT_PATH.OCR },
    component: <OCRTest />,
  },
]

export default AccountRoutes
