import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogoutMutation } from 'generated/graphql'

export const Logout = () => {
  const history = useHistory()
  const [logout, { client }] = useLogoutMutation()
  useEffect(() => {
    async function userLogout() {
      await logout()
      await client!.resetStore()
    }
    userLogout()
    localStorage.clear()

    history.push('/login')
  })
  return <></>
}
