import {
  ContentWrapper,
  Spinner,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import ReviewPayment from 'assets/svg/checkin-review.svg'
import { RedirectUrl } from 'containers/App/Client'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { SERVICE_PATH } from 'containers/Module/Services/Routes'
import { amtStr } from 'containers/Utils/numFormatter'
import dateFormat from 'dateformat'
import {
  PaymentClass,
  PaymentStatus,
  PaymentType,
  useFrontDeskPaymentInsertMutation,
  useOnlinePaymentMutation,
  usePaymentRegistrationListingQuery,
} from 'generated/graphql'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { BOOKING_PATH } from '../Routes'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

// interface Props {
//   Dialog?: {
//     Open?: boolean
//     Mode?: DialogMode
//   }
// }
export const CheckInSummary = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const location = useLocation()
  const history = useHistory()

  const localState = location.state as any
  const {
    data: { PaymentRegistrationListing } = { PaymentRegistrationListing: null },
  } = usePaymentRegistrationListingQuery({
    variables: {
      RegistrationID: localState?.Guest?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  // const initialState: Props = {
  //   Dialog: {
  //     Open: false,
  //     Mode: '',
  //   },
  // }

  const TourismTax = PaymentRegistrationListing?.TaxLedger?.filter(
    x => x.TaxType === 'TourismTax'
  )
  const TourismTaxAmount =
    TourismTax?.reduce((ac, curr) => {
      return ac + curr?.TaxAmount
    }, 0) || 0
  const TTxNights = TourismTax?.length
  const TTxDate = TourismTax && TourismTax[0]?.TrxDate

  const HeritageTax = PaymentRegistrationListing?.TaxLedger?.filter(
    x => x.TaxType === 'HeritageTax'
  )
  const HeritageTaxAmount =
    HeritageTax?.reduce((ac, curr) => {
      return ac + curr?.TaxAmount
    }, 0) || 0
  const HeritageTaxNights = HeritageTax?.length
  const HeritageTaxDate = HeritageTax && HeritageTax[0]?.TrxDate

  const TotalAmount = TourismTaxAmount + HeritageTaxAmount
  // const reducer: Reducer<Props, IAction> = (state, action) => {
  //   switch (action.type) {
  //     case 'OpenDialog':
  //       return { ...state, Dialog: { Open: true, Mode: '' } }
  //     case 'CloseDialog':
  //       return { ...state, Dialog: { Open: false, Mode: '' } }
  //     case 'Reset':
  //       return initialState
  //     default:
  //       return { ...state, [action.type]: action.payload }
  //   }
  // }
  // const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Summary',
          props: {
            onClick: () =>
              localState?.Guest?.IsPrincipal
                ? history.push(SERVICE_PATH.LIST)
                : history.push(BOOKING_PATH.ROOT),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const Input: any[] = PaymentRegistrationListing?.TaxLedger?.map(x => ({
    BookingID: localState?.BookingDetails?.ID,
    HotelID: localState?.Hotel?.ID,
    PaymentType: PaymentType['Online'],
    // TaxAmount: x.TaxAmount,
    Amount: x.TaxAmount,
    RegistrationID: x.RegistrationID,
    ChargeType: 'Taxes',
    PaymentClass: PaymentClass['Room'],
    LedgerType: 'Tax',
    LedgerTypeID: x.ID,
    ModuleName: 'InHouse',
    PaymentStatus: PaymentStatus.Pending,
    AccountID: localState?.Hotel?.AccountID,
  }))
  const [OnlinePayment, { loading: OnlineLoading }] = useOnlinePaymentMutation(
    {}
  )

  const [
    FrontDeskPaymentInsert,
    { loading },
  ] = useFrontDeskPaymentInsertMutation({
    variables: {
      TotalPaymentAmount: Number(TotalAmount),
      input: Input,
      IsGroupFolio: false,
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        return RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
          },
        })
      })
    },
    onCompleted: data => {
      if (data.FrontDeskPaymentInsert.ReceiptNo !== '') {
        localStorage.setItem(
          'FDReceiptNo',
          data.FrontDeskPaymentInsert.ReceiptNo
        )
        OnlinePayment({
          variables: {
            redirectUrl: `${RedirectUrl}/services/redirect/:status`,
            PayAmount:
              process.env.REACT_APP_API_URL === 'prod-build' ||
              process.env.REACT_APP_API_URL === 'uat-build'
                ? Number(TotalAmount)
                : 1,
            Detail: `Outstanding Payment`,
            Title: ` Outstanding Payment`,
            HotelID: localState?.Hotel?.ID,

            ReceiptNo: data.FrontDeskPaymentInsert?.ReceiptNo || null,
          },
        }).then(i => {
          if (i.data.OnlinePayment?.code === 'SUCCESS') {
            localStorage.setItem(
              'CheckOutID',
              i.data.OnlinePayment.item.checkoutId
            )
            localStorage.setItem('HotelID', localState?.Hotel?.ID)
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Redirecting to payment gateway...',
              },
            })
            window.location.assign(i.data.OnlinePayment.item.url)
          } else {
            i.data.OnlinePayment.error.code !== ''
              ? RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: i.data.OnlinePayment.error.message,
                  },
                })
              : RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: 'Insert Fail.',
                  },
                })
          }
        })
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Payment Fail',
          },
        })
      }
    },
  })
  return (
    <>
      {loading && <Spinner />}
      <ContentWrapper footer>
        <StyledCardContent>
          <div
            style={{
              marginLeft: '-14px',
              marginRight: '-14px ',
            }}
          >
            <img src={ReviewPayment} alt="payment" style={{ width: '100%' }} />
          </div>
        </StyledCardContent>

        <StyledCardContent>
          <div className="smTitle flex-width">Review Payment</div>
          <div className="b-b m-t-14 m-b-14" />
          {TotalAmount === 0 && (
            <div className="xsTitle color-text-1 flex-width">
              No Record Found.
            </div>
          )}
          {TourismTaxAmount > 0 ? (
            <div className="smTitle flex-width m-b-8">
              <div className="flex-space">
                <div className="xsTitle color-text-1">
                  Tourism Tax - {TTxNights} Night(s)
                </div>
                <div className="desc color-text-2">
                  {dateFormat(TTxDate, 'dd mmm yyyy')}
                </div>
              </div>
              <div className="xsTitle color-text-1 icon-text">
                <MonetizationOnIcon /> {amtStr(TourismTaxAmount)}
              </div>
            </div>
          ) : null}
          {HeritageTaxAmount > 0 ? (
            <div className="smTitle flex-width m-b-8">
              <div className="flex-space">
                <div className="xsTitle color-text-1">
                  Heritage Tax - {HeritageTaxNights} Night(s)
                </div>
                <div className="desc color-text-2">
                  {dateFormat(HeritageTaxDate, 'dd mmm yyyy')}
                </div>
              </div>
              <div className="xsTitle color-text-1 icon-text">
                <MonetizationOnIcon />
                {amtStr(HeritageTaxAmount)}
              </div>
            </div>
          ) : null}
          <div className="b-b m-t-14 m-b-14" />
          <div className="flex-width  ">
            <span className="fs-14 fw-bold flex-space">Total</span>
            <span className="fs-14 fw-bold title icon-text">
              {' '}
              <MonetizationOnIcon /> {amtStr(TotalAmount)}
            </span>
          </div>
        </StyledCardContent>
      </ContentWrapper>
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () => FrontDeskPaymentInsert(),
                disabled: TotalAmount === 0,
              },

              name: 'Pay Now',
            },
          ],
        }}
      />
    </>
  )
}
