import {
  ContentWrapper,
  Spinner,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import {
  Avatar,
  Badge,
  Fab,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Publish } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import {
  Country,
  Nationality,
  State,
  useGuestUpdateMutation,
  useLoggedInGuestProfileQuery,
} from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router'

interface ProfileProps {
  FullName?: string
  NRIC?: string
  Nationality?: string
  MobileNo?: string
  Email?: string
  Address?: {
    address?: string
    country?: string
    state?: string
    city?: string
    postCode?: string
  }
  ProfileAvatar?: any
  ImagePreview?: any
}
export const MyProfile: FC = () => {
  const {
    loading: infoLoading,
    data: { loggedInGuestProfile } = { loggedInGuestProfile: null },
  } = useLoggedInGuestProfileQuery({
    fetchPolicy: 'network-only',
  })
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)

  const history = useHistory()
  let NationalityEnum: any = Object.values(Nationality).map(i => {
    return i.replace(/_/g, ' ')
  })
  let CountryEnum: any = Object.values(Country).map(i => {
    return i.replace(/_/g, ' ')
  })
  let StateEnum: any = Object.values(State).map(i => {
    return i.replace(/_/g, ' ')
  })

  const initialState: ProfileProps = {
    FullName: '',
    NRIC: '',
    Nationality: '',
    MobileNo: '',
    Email: '',
    Address: {
      address: '',
      country: '',
      state: '',
      city: '',
      postCode: '',
    },
    ProfileAvatar: null,
    ImagePreview: null,
  }

  const reducer: Reducer<ProfileProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'My Profile',
          props: {
            onClick: () => history.goBack(),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (loggedInGuestProfile) {
      dispatch({
        type: 'ImagePreview',
        payload: loggedInGuestProfile?.Contact?.ProfilePictureGallery?.ImageURL,
      })
      dispatch({
        type: 'FullName',
        payload: loggedInGuestProfile?.Contact?.FullName,
      })
      dispatch({
        type: 'MobileNo',
        payload: loggedInGuestProfile?.Contact?.MobileNo,
      })
      dispatch({
        type: 'NRIC',
        payload: loggedInGuestProfile?.Contact?.NRIC,
      })
      dispatch({
        type: 'Email',
        payload: loggedInGuestProfile?.Contact?.Email,
      })
      dispatch({
        type: 'Nationality',
        payload: loggedInGuestProfile?.Contact?.Nationality,
      })
      dispatch({
        type: 'Address',
        payload: {
          address: loggedInGuestProfile?.Contact?.Address?.address,
          country: loggedInGuestProfile?.Contact?.Address?.country,
          city: loggedInGuestProfile?.Contact?.Address?.city,
          state: loggedInGuestProfile?.Contact?.Address?.state,
          postCode: loggedInGuestProfile?.Contact?.Address?.postCode,
        },
      })
    }
  }, [loggedInGuestProfile])
  const [GuestUpdate, { loading }] = useGuestUpdateMutation({
    variables: {
      ProfilePictureAttachmentInput: state.ProfileAvatar,
      GuestProfileInput: {
        ID: loggedInGuestProfile?.ID,
        ContactID: loggedInGuestProfile?.Contact?.ID,
        Login: loggedInGuestProfile?.Contact?.Email,
      },
      ContactInput: {
        ID: loggedInGuestProfile?.Contact?.ID,
        Nationality: Nationality[state.Nationality],
        FullName: state.FullName,
        MobileNo: state.MobileNo,
        NRIC: state.NRIC,
        Address: state.Address,
      },
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.GuestUpdate) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Update Successfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.goBack()
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Update Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
  })

  const handleUploadProfileAvatar = event => {
    dispatch({
      type: 'ProfileAvatar',
      payload: event.target.files[0],
    })
    let reader = new FileReader()

    reader.onloadend = () => {
      dispatch({
        type: 'ImagePreview',
        payload: reader.result,
      })
    }
    reader.readAsDataURL(event.target.files[0])
  }

  return (
    <>
      {(loading || infoLoading) && <Spinner />}
      <ContentWrapper footer>
        <StyledCardContent>
          <div className="avatar-section">
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <Fab
                  color="primary"
                  size="small"
                  aria-label="add"
                  className="float-img-upload "
                >
                  <label htmlFor="icon-button-file"></label>
                  <Publish />
                </Fab>
              }
            >
              <Avatar
                src={state.ImagePreview}
                alt=""
                className="avatar-user-profile"
              />
            </Badge>
          </div>
          <input
            type="file"
            hidden
            accept="image/*"
            id="icon-button-file"
            onChange={handleUploadProfileAvatar}
          />
          <TextField
            label="Name"
            margin="normal"
            fullWidth
            required
            value={state.FullName}
            onChange={e =>
              dispatch({
                type: 'FullName',
                payload: e.target.value,
              })
            }
          />
          <TextField
            label="Mobile No."
            value={state.MobileNo}
            margin="normal"
            fullWidth
            onChange={e =>
              dispatch({
                type: 'MobileNo',
                payload: e.target.value,
              })
            }
          />
          <TextField
            label="Email Address"
            value={state.Email}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />
          <Grid container justify="flex-start">
            <Grid
              item
              xs={6}
              style={{
                paddingRight: '6px',
              }}
            >
              <Autocomplete
                fullWidth
                // className="left"
                options={NationalityEnum}
                getOptionLabel={option => option || ''}
                value={state.Nationality || ''}
                onChange={(_, value) => {
                  dispatch({
                    type: 'Nationality',
                    payload: value,
                  })
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Nationality"
                    margin="normal"
                    style={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingLeft: '6px',
              }}
            >
              <NumberFormat
                customInput={TextField}
                required
                label={
                  state.Nationality?.toLowerCase() === 'malaysian'
                    ? 'NRIC No.'
                    : !state.Nationality
                    ? 'NRIC / Passport No.'
                    : 'Passport No.'
                }
                value={state.NRIC}
                fullWidth
                margin="normal"
                onValueChange={e => {
                  dispatch({
                    type: 'NRIC',
                    payload: e.value,
                  })
                }}
                format={
                  state.Nationality?.toLowerCase() === 'malaysian'
                    ? '######-##-####'
                    : null
                }
                mask={
                  state.Nationality?.toLowerCase() === 'malaysian' ? '_' : ''
                }
              />
            </Grid>
          </Grid>

          <TextField
            value={state.Address.address}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            rowsMax={6}
            onChange={e =>
              dispatch({
                type: 'Address',
                payload: {
                  ...state.Address,
                  address: e.target.value,
                },
              })
            }
            label="Address"
            margin="normal"
            fullWidth
          />
          <Grid container justify="flex-start">
            <Grid
              item
              xs={6}
              style={{
                paddingRight: '6px',
              }}
            >
              <Autocomplete
                fullWidth
                options={CountryEnum}
                getOptionLabel={option => option || ''}
                value={state.Address?.country || ''}
                onChange={(_, value) => {
                  dispatch({
                    type: 'Address',
                    payload: {
                      ...state.Address,
                      country: value,
                    },
                  })
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Country"
                    margin="normal"
                    style={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingLeft: '6px',
              }}
            >
              {state.Address?.country?.toLowerCase() === 'malaysia' ? (
                <TextField
                  label="State"
                  fullWidth
                  select
                  required
                  margin="normal"
                  value={state.Address.state}
                  name="state"
                  onChange={e => {
                    dispatch({
                      type: 'Address',
                      payload: {
                        ...state.Address,
                        state: e.target.value,
                      },
                    })
                  }}
                >
                  {StateEnum.map(i => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  label="State"
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="state"
                  value={state.Address.state}
                  onChange={e => {
                    dispatch({
                      type: 'Address',
                      payload: {
                        ...state.Address,
                        state: e.target.value,
                      },
                    })
                  }}
                />
              )}
            </Grid>
          </Grid>
          <TextField
            label="City"
            fullWidth
            margin="normal"
            className="left"
            InputLabelProps={{
              shrink: true,
            }}
            value={state.Address?.city}
            onChange={e => {
              dispatch({
                type: 'Address',
                payload: {
                  ...state.Address,
                  city: e.target.value,
                },
              })
            }}
          />
          <TextField
            label="Postcode"
            fullWidth
            margin="normal"
            className="right"
            InputLabelProps={{
              shrink: true,
            }}
            value={state.Address?.postCode}
            onChange={e => {
              dispatch({
                type: 'Address',
                payload: {
                  ...state.Address,
                  postCode: e.target.value,
                },
              })
            }}
          />
        </StyledCardContent>
      </ContentWrapper>
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () => GuestUpdate(),
                disabled:
                  !state.Nationality ||
                  !state.NRIC ||
                  !state.Address?.country ||
                  !state.Address?.state,
              },
              name: 'Save',
            },
          ],
        }}
      />
    </>
  )
}
