import React from 'react'
import * as BlinkIDSDK from '@microblink/blinkid-in-browser-sdk'
import { b64toBlob } from 'containers/Utils/b64toBlob'
import Compressor from 'compressorjs'

export const useBlinkID = () => {
  // General UI helpers
  const [icResults, setICResults] = React.useState(null)
  const [icImage, setImage] = React.useState(null)

  const [cameraFeeds, setCameraFeed] = React.useState(null)
  const [cameraFeedbacks, setCameraFeedback] = React.useState(null)
  const [uploadFile, setUploadFile] = React.useState(null)
  const [progress, setProgress] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  // console.log(cameraFeed)
  const initialMessageEl = document.getElementById('msg') as HTMLHeadingElement
  // const progressEl = document.getElementById(
  //   'load-progress'
  // ) as HTMLProgressElement

  // UI elements for scanning feedback
  const cameraFeed = document?.getElementById('camera-feed') as HTMLVideoElement
  const cameraFeedback = document.getElementById(
    'camera-feedback'
  ) as HTMLCanvasElement
  const drawContext = cameraFeedback?.getContext(
    '2d'
  ) as CanvasRenderingContext2D
  const scanFeedback = document.getElementById(
    'camera-guides'
  ) as HTMLParagraphElement
  const scanImageElement = document.getElementById(
    'target-image'
  ) as HTMLImageElement
  const inputImageFile = document.getElementById(
    'image-file'
  ) as HTMLInputElement
  /**
   * Check browser support, customize settings and load WASM SDK.
   */
  function imageDataToBase64(imageData: ImageData): string {
    const canvas = document.createElement('canvas')

    canvas.width = imageData.width
    canvas.height = imageData.height

    const context = canvas.getContext('2d')
    context?.putImageData(imageData, 0, 0)

    return canvas.toDataURL()
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new Compressor(new File([u8arr], filename, { type: mime }), {
      strict: false,
      drew(context, canvas) {
        context.fillStyle = '#000'
        context.font = '8vh serif'
        context.textAlign = 'center'
        context.fillText('HotelX use only', canvas.width / 2, canvas.height / 2)
      },
      success: function (result) {
        var newImage = new Image()
        newImage.src = URL.createObjectURL(result)
        newImage.alt = 'Compressed image'
        let reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onload = () => {
          setImage({
            blob: result,
            image: reader?.result,
          })
          //save file in database
        }
        reader.onerror = error => {
          console.log(error)
        }
      },
      error: function (err) {
        window.alert(err.message)
      },
    })
  }
  const setWaterMark = file => {
    new Compressor(file, {
      strict: false,
      drew(context, canvas) {
        context.fillStyle = '#000'
        context.font = '8vh serif'
        context.textAlign = 'center'
        context.fillText('HotelX use only', canvas.width / 2, canvas.height / 2)
      },
      success: function (result) {
        var newImage = new Image()
        newImage.src = URL.createObjectURL(result)
        newImage.alt = 'Compressed image'
        let reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onload = () => {
          setImage({
            blob: result,
            image: reader.result,
          })
          //save file in database
        }
        reader.onerror = error => {
          console.log(error)
        }
      },
      error: function (err) {
        window.alert(err.message)
      },
    })
  }
  function handleBlinkID() {
    // Check if browser has proper support for WebAssembly
    if (!BlinkIDSDK.isBrowserSupported()) {
      initialMessageEl.innerText = 'This browser is not supported!'

      return alert('This browser is not supported!')
    }

    // 1. It's possible to obtain a free trial license key on microblink.com
    const licenseKey =
      'sRwAAAYJbG9jYWxob3N0r/lOPgo/w35CpJmmLkURzbQnjMGgf4xd4r8RMsqioWscMKuldYIJRbLBQAHbNw1HW8UIEVJ5Kv4SWUweFA5g4446lPtpwRODPbRUp8wqyff3vd4RnLuamcidpiZm7ZMCPG2kmY5Qc0Vq/hQKW2zxbbpppcs71WJKiGqGX8rhRG9kPq0mIapYEXMGq09tEarqEx6H455tst1w8Y47OSh2QubpSptPBdy/7Qo='

    const devLicenseKey =
      'sRwAAAYYaG90ZWx4LWRldi1ndWVzdC5pZmNhLmlvpciAEViAxFGqNG7hPxTjImbFZaI9C8aZoArJ4hCtCV9B6Q7t0qS1evsR/0v95EBQ84aK/CDE1NlhY92X98fpk4U19HEjqW5SLzbjSkKDfMk/T812ei4FTL7dhmmFttobg2ZmOxINq1lLxxIuWzyBOp4LIy/Ju9ae0aa+5S7giOUzXQ7B8LQPQwoQwqph8rz7I19yf/7phO0XOuWuOrH19NHGTQvaLkYkoLo='
    // 2. Create instance of SDK load settings with your license key
    const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(
      process.env.REACT_APP_API_URL === 'dev-build' ? devLicenseKey : licenseKey
    )

    // [OPTIONAL] Change default settings

    // Show or hide hello message in browser console when WASM is successfully loaded
    loadSettings.allowHelloMessage = true

    // In order to provide better UX, display progress bar while loading the SDK
    loadSettings.loadProgressCallback = (progress: number) => {
      setProgress(progress)
      if (progress !== 0) {
        setLoading(true)
      } else {
        setLoading(false)
      }
    }
    // console.log(progress)

    // Set absolute location of the engine, i.e. WASM and support JS files
    loadSettings.engineLocation =
      'https://unpkg.com/@microblink/blinkid-in-browser-sdk@5.14.2/resources/'
    console.log(loadSettings.engineLocation)
    // 3. Load SDK
    BlinkIDSDK.loadWasmModule(loadSettings).then(
      (sdk: BlinkIDSDK.WasmSDK) => {
        // document.getElementById('screen-initial')?.classList.add('hidden')
        // document.getElementById('screen-start')?.classList.remove('hidden')

        startScan(sdk)
        // startScanFile(sdk, uploadFile)
      },
      (error: any) => {
        // initialMessageEl?.innerText = 'Failed to load SDK!'
        console.error('Failed to load SDK!', error)
      }
    )
  }
  function handleBlinkIDFile() {
    // Check if browser has proper support for WebAssembly
    if (!BlinkIDSDK.isBrowserSupported()) {
      initialMessageEl.innerText = 'This browser is not supported!'

      return alert('This browser is not supported!')
    }

    // 1. It's possible to obtain a free trial license key on microblink.com
    const licenseKey =
      'sRwAAAYJbG9jYWxob3N0r/lOPgo/w35CpJmmLkURzbQnjMGgf4xd4r8RMsqioWscMKuldYIJRbLBQAHbNw1HW8UIEVJ5Kv4SWUweFA5g4446lPtpwRODPbRUp8wqyff3vd4RnLuamcidpiZm7ZMCPG2kmY5Qc0Vq/hQKW2zxbbpppcs71WJKiGqGX8rhRG9kPq0mIapYEXMGq09tEarqEx6H455tst1w8Y47OSh2QubpSptPBdy/7Qo='

    const devLicenseKey =
      'sRwAAAYYaG90ZWx4LWRldi1ndWVzdC5pZmNhLmlvpciAEViAxFGqNG7hPxTjImbFZaI9C8aZoArJ4hCtCV9B6Q7t0qS1evsR/0v95EBQ84aK/CDE1NlhY92X98fpk4U19HEjqW5SLzbjSkKDfMk/T812ei4FTL7dhmmFttobg2ZmOxINq1lLxxIuWzyBOp4LIy/Ju9ae0aa+5S7giOUzXQ7B8LQPQwoQwqph8rz7I19yf/7phO0XOuWuOrH19NHGTQvaLkYkoLo='
    // 2. Create instance of SDK load settings with your license key
    const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(
      process.env.REACT_APP_API_URL === 'dev-build' ? devLicenseKey : licenseKey
    )

    // [OPTIONAL] Change default settings

    // Show or hide hello message in browser console when WASM is successfully loaded
    loadSettings.allowHelloMessage = true

    // In order to provide better UX, display progress bar while loading the SDK
    loadSettings.loadProgressCallback = (progress: number) => {
      setProgress(progress)
      if (progress !== 0) {
        setLoading(true)
      } else {
        setLoading(false)
      }
    }
    // console.log(progress)

    // Set absolute location of the engine, i.e. WASM and support JS files
    loadSettings.engineLocation =
      'https://unpkg.com/@microblink/blinkid-in-browser-sdk@5.14.2/resources/'
    console.log(loadSettings.engineLocation)
    // 3. Load SDK
    BlinkIDSDK.loadWasmModule(loadSettings).then(
      (sdk: BlinkIDSDK.WasmSDK) => {
        // document.getElementById('screen-initial')?.classList.add('hidden')
        // document.getElementById('screen-start')?.classList.remove('hidden')
        document
          .getElementById('image-file')
          ?.addEventListener('change', (ev: any) => {
            ev.preventDefault()
            startScanFile(sdk, ev.target.files)
          })
      },
      (error: any) => {
        // initialMessageEl?.innerText = 'Failed to load SDK!'
        console.error('Failed to load SDK!', error)
      }
    )
  }
  async function startScanFile(sdk, fileList) {
    console.log(fileList)

    document.getElementById('screen-start')?.classList.add('hidden')
    document.getElementById('screen-scanning')?.classList.remove('hidden')
    // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
    //
    // Generic ID Recognizer - scan various ID documents
    // ID Barcode Recognizer - scan barcodes from various ID documents
    const genericIDRecognizer = await BlinkIDSDK.createBlinkIdRecognizer(sdk)
    const idBarcodeRecognizer = await BlinkIDSDK.createIdBarcodeRecognizer(sdk)
    // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more
    //    recognizer objects.
    const recognizerRunner = await BlinkIDSDK.createRecognizerRunner(
      // SDK instance to use
      sdk,
      // List of recognizer objects that will be associated with created RecognizerRunner object
      [genericIDRecognizer],
      // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition
      false
    )
    // 3. Prepare image for scan action - keep in mind that SDK can only process images represented in
    //    internal CapturedFrame data structure. Therefore, auxiliary method "captureFrame" is provided.
    // Make sure that image file is provided
    let file = null
    const imageRegex = RegExp(/^image\//)
    for (let i = 0; i < fileList.length; ++i) {
      if (imageRegex.exec(fileList[i].type)) {
        file = fileList[i]
      }
    }
    if (!file) {
      alert('No image files provided!')
      // Release memory on WebAssembly heap used by the RecognizerRunner
      recognizerRunner?.delete()
      // Release memory on WebAssembly heap used by the recognizer
      genericIDRecognizer?.delete()
      idBarcodeRecognizer?.delete()
      // inputImageFile?.value = ''
      return
    }
    scanImageElement.src = URL.createObjectURL(file)
    await scanImageElement.decode()
    const imageFrame = BlinkIDSDK.captureFrame(scanImageElement)
    // 4. Start the recognition and await for the results
    const processResult = await recognizerRunner.processImage(imageFrame)
    setWaterMark(file)
    // 5. If recognition was successful, obtain the result and display it
    if (processResult !== BlinkIDSDK.RecognizerResultState.Empty) {
      const genericIDResults = await genericIDRecognizer.getResult()
      if (genericIDResults.state !== BlinkIDSDK.RecognizerResultState.Empty) {
        console.log('BlinkIDGeneric results', genericIDResults)
        console.log('address', genericIDResults?.address.split('\n'))
        const addressResults = genericIDResults?.address.split('\n')
        const postCodeCityRow = addressResults?.slice(-2)[0]
        const FullName = genericIDResults.fullName
        const NRIC = genericIDResults.documentNumber
        const Address = addressResults?.slice(0, -2).join(', ')
        const City =
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            .charAt(0)
            .toUpperCase() +
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            ?.slice(1)
        const State =
          addressResults?.slice(-1)[0]?.toLowerCase().charAt(0).toUpperCase() +
          addressResults?.slice(-1)[0]?.toLowerCase()?.slice(1)
        const Country =
          genericIDResults?.classInfo?.countryName
            ?.toLowerCase()
            ?.charAt(0)
            .toUpperCase() +
          genericIDResults?.classInfo?.countryName?.toLowerCase().slice(1)
        const PostCode = postCodeCityRow?.substr(
          0,
          postCodeCityRow?.indexOf(' ')
        )
        const ICResults = {
          FullName,
          NRIC,
          Address,
          City,
          State,
          Country,
          PostCode,
        }
        setICResults(ICResults)
        recognizerRunner?.delete()
        // Release memory on WebAssembly heap used by the recognizer
        genericIDRecognizer?.delete()
        idBarcodeRecognizer?.delete()
      }
      const idBarcodeResult = await idBarcodeRecognizer.getResult()
      if (idBarcodeResult.state !== BlinkIDSDK.RecognizerResultState.Empty) {
        console.log('IDBarcode results', idBarcodeResult)
        //         alert(`Hello, ${idBarcodeResult.firstName} ${idBarcodeResult.lastName}!
        // You were born on ${idBarcodeResult.dateOfBirth.year}-${idBarcodeResult.dateOfBirth.month}-${idBarcodeResult.dateOfBirth.day}.`)
      }
    } else {
      alert('Could not extract information!')
    }
    // Get SuccessFrameGrabberRecognizer results for BlinkIDRecognizer
    // const genericIDSfgResults = await genericIDRecognizerSfg.getResult()
    // function imgDataToImage(imagedata) {
    //   var canvas = document.createElement('canvas')
    //   var ctx = canvas.getContext('2d')
    //   canvas.width = imagedata.width
    //   canvas.height = imagedata.height
    //   ctx.putImageData(imagedata, 0, 0)

    //   var image = new Image()
    //   image.src = canvas.toDataURL()
    //   console.log(canvas.toDataURL())
    //   return image
    // }
    // if (genericIDSfgResults.state !== BlinkIDSDK.RecognizerResultState.Empty) {
    //   console.log(
    //     'Success frame for BlinkIDRecognizer',
    //     imgDataToImage(genericIDSfgResults?.successFrame)
    //   )
    //   // b64toBlob(imgDataToImage(genericIDSfgResults?.successFrame), 'png', 512)
    // } else {
    //   alert('Could not extract information!')
    // }
    // 7. Release all resources allocated on the WebAssembly heap and associated with camera stream
    // Release memory on WebAssembly heap used by the RecognizerRunner
    recognizerRunner?.delete()
    // Release memory on WebAssembly heap used by the recognizer
    genericIDRecognizer?.delete()
    idBarcodeRecognizer?.delete()
    // genericIDRecognizerSfg?.delete()
    // Hide scanning screen and show scan button again
    // inputImageFile.value = ''
    document.getElementById('screen-start')?.classList.remove('hidden')
    document.getElementById('screen-scanning')?.classList.add('hidden')
  }
  /**
   * Scan single side of identity document with web camera.
   */
  async function startScan(sdk: BlinkIDSDK.WasmSDK) {
    // document.getElementById('screen-start')?.classList.add('hidden')
    // document.getElementById('screen-scanning')?.classList.remove('hidden')

    // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
    //

    // Generic ID Recognizer - scan various ID documents

    // ID Barcode Recognizer - scan barcodes from various ID documents
    const genericIDRecognizer = await BlinkIDSDK.createBlinkIdRecognizer(sdk)
    const idBarcodeRecognizer = await BlinkIDSDK.createIdBarcodeRecognizer(sdk)

    // [OPTIONAL] Create a callbacks object that will receive recognition events, such as detected object location etc.
    const callbacks = {
      onQuadDetection: (quad: BlinkIDSDK.DisplayableQuad) => drawQuad(quad),
      onDetectionFailed: () => updateScanFeedback('Detection failed', true),
    }

    // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more
    const genericIDRecognizerSfg = await BlinkIDSDK.createSuccessFrameGrabberRecognizer(
      sdk,
      genericIDRecognizer
    )
    //    recognizer objects.
    const recognizerRunner = await BlinkIDSDK.createRecognizerRunner(
      // SDK instance to use
      sdk,

      // List of recognizer objects that will be associated with created RecognizerRunner object
      [genericIDRecognizerSfg],

      // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition
      false,

      // [OPTIONAL] Callbacks object that will receive recognition events
      callbacks
    )
    // console.log(cameraFeed, 'startsCAN')

    // 3. Create a VideoRecognizer object and attach it to HTMLVideoElement that will be used for displaying the camera feed
    const videoRecognizer = await BlinkIDSDK.VideoRecognizer.createVideoRecognizerFromCameraStream(
      cameraFeed,
      recognizerRunner
    )

    // 4. Start the recognition and await for the results
    const processResult = await videoRecognizer.recognize()

    // 5. If recognition was successful, obtain the result and display it
    const genericIDResults = await genericIDRecognizer.getResult()
    const genericIDSfgResults = await genericIDRecognizerSfg.getResult()
    if (processResult !== BlinkIDSDK.RecognizerResultState.Empty) {
      if (genericIDResults.state !== BlinkIDSDK.RecognizerResultState.Empty) {
        // alert(
        dataURLtoFile(
          imageDataToBase64(genericIDSfgResults?.successFrame),
          'ic_front'
        )
        //   `Hello, ${fullName} !\n You were born on ${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}.`
        // )
        const addressResults = genericIDResults?.address.split('\n')
        const postCodeCityRow = addressResults?.slice(-2)[0]
        const FullName = genericIDResults.fullName
        const NRIC = genericIDResults.documentNumber
        const Address = addressResults?.slice(0, -2).join(', ')
        const City =
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            .charAt(0)
            .toUpperCase() +
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            ?.slice(1)
        const State =
          addressResults?.slice(-1)[0]?.toLowerCase().charAt(0).toUpperCase() +
          addressResults?.slice(-1)[0]?.toLowerCase()?.slice(1)
        const Country =
          genericIDResults?.classInfo?.countryName
            ?.toLowerCase()
            ?.charAt(0)
            .toUpperCase() +
          genericIDResults?.classInfo?.countryName?.toLowerCase().slice(1)
        const PostCode = postCodeCityRow?.substr(
          0,
          postCodeCityRow?.indexOf(' ')
        )
        const ICResults = {
          FullName,
          NRIC,
          Address,
          City,
          State,
          Country,
          PostCode,
        }
        setICResults(ICResults)
        videoRecognizer?.releaseVideoFeed()

        recognizerRunner?.delete()

        genericIDRecognizer?.delete()
        idBarcodeRecognizer?.delete()
        genericIDRecognizerSfg?.delete()
        // Clear any leftovers drawn to canvas
        clearDrawCanvas()
      }
    } else {
      alert('Could not extract information!')
    }

    // 7. Release all resources allocated on the WebAssembly heap and associated with camera stream

    videoRecognizer?.releaseVideoFeed()

    recognizerRunner?.delete()

    genericIDRecognizer?.delete()
    idBarcodeRecognizer?.delete()
    genericIDRecognizerSfg?.delete()
    // Clear any leftovers drawn to canvas
    clearDrawCanvas()

    // Hide scanning screen and show scan button again
    document.getElementById('screen-start')?.classList.remove('hidden')
    document.getElementById('screen-scanning')?.classList.add('hidden')
  }

  /**
   * Utility functions for drawing detected quadrilateral onto canvas.
   */
  function drawQuad(quad: BlinkIDSDK.DisplayableQuad) {
    clearDrawCanvas()

    // Based on detection status, show appropriate color and message
    setupColor(quad)
    setupMessage(quad)
    applyTransform(quad.transformMatrix)
    drawContext.beginPath()
    drawContext.moveTo(quad.topLeft.x, quad.topLeft.y)
    drawContext.lineTo(quad.topRight.x, quad.topRight.y)
    drawContext.lineTo(quad.bottomRight.x, quad.bottomRight.y)
    drawContext.lineTo(quad.bottomLeft.x, quad.bottomLeft.y)
    drawContext.closePath()
    drawContext.stroke()
  }

  /**
   * This function will make sure that coordinate system associated with detectionResult
   * canvas will match the coordinate system of the image being recognized.
   */
  function applyTransform(transformMatrix: Float32Array) {
    const canvasAR = cameraFeedback.width / cameraFeedback.height
    const videoAR = cameraFeed.videoWidth / cameraFeed.videoHeight
    let xOffset = 0
    let yOffset = 0
    let scaledVideoHeight = 0
    let scaledVideoWidth = 0
    if (canvasAR > videoAR) {
      // pillarboxing: https://en.wikipedia.org/wiki/Pillarbox
      scaledVideoHeight = cameraFeedback.height
      scaledVideoWidth = videoAR * scaledVideoHeight
      xOffset = (cameraFeedback.width - scaledVideoWidth) / 2
    } else {
      // letterboxing: https://en.wikipedia.org/wiki/Letterboxing_(filming)
      scaledVideoWidth = cameraFeedback.width
      scaledVideoHeight = scaledVideoWidth / videoAR
      yOffset = (cameraFeedback.height - scaledVideoHeight) / 2
    }

    // first transform canvas for offset of video preview within the HTML video element (i.e. correct letterboxing or pillarboxing)
    drawContext.translate(xOffset, yOffset)

    // second, scale the canvas to fit the scaled video
    drawContext.scale(
      scaledVideoWidth / cameraFeed.videoWidth,
      scaledVideoHeight / cameraFeed.videoHeight
    )

    // finally, apply transformation from image coordinate system to

    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
    drawContext.transform(
      transformMatrix[0],
      transformMatrix[3],
      transformMatrix[1],
      transformMatrix[4],
      transformMatrix[2],
      transformMatrix[5]
    )
  }

  function clearDrawCanvas() {
    cameraFeedback.width = cameraFeedback.clientWidth
    cameraFeedback.height = cameraFeedback.clientHeight
    drawContext.clearRect(0, 0, cameraFeedback.width, cameraFeedback.height)
  }

  function setupColor(displayable: BlinkIDSDK.Displayable) {
    let color = '#FFFF00FF'
    if (displayable.detectionStatus === 0) {
      color = '#FF0000FF'
    } else if (displayable.detectionStatus === 1) {
      color = '#00FF00FF'
    }
    drawContext.fillStyle = color
    drawContext.strokeStyle = color
    drawContext.lineWidth = 5
  }

  function setupMessage(displayable: BlinkIDSDK.Displayable) {
    switch (displayable.detectionStatus) {
      case BlinkIDSDK.DetectionStatus.Fail:
        updateScanFeedback('Scanning...')
        break
      case BlinkIDSDK.DetectionStatus.Success:
      case BlinkIDSDK.DetectionStatus.FallbackSuccess:
        updateScanFeedback('Scan Successfully')
        break
      case BlinkIDSDK.DetectionStatus.CameraAtAngle:
        updateScanFeedback('Adjust the angle')
        break
      case BlinkIDSDK.DetectionStatus.CameraTooHigh:
        updateScanFeedback('Move ID closer')
        break
      case BlinkIDSDK.DetectionStatus.CameraTooNear:
      case BlinkIDSDK.DetectionStatus.DocumentTooCloseToEdge:
      case BlinkIDSDK.DetectionStatus.Partial:
        updateScanFeedback('Move ID farther')
        break
      default:
        console.warn('Unhandled detection status!', displayable.detectionStatus)
    }
  }

  let scanFeedbackLock = false

  /**
   * The purpose of this function is to ensure that scan feedback message is
   * visible for at least 1 second.
   */
  function updateScanFeedback(message: string, force?: boolean) {
    if (scanFeedbackLock && !force) {
      return
    }
    scanFeedbackLock = true
    scanFeedback.innerText = message
    window.setTimeout(() => (scanFeedbackLock = false), 1000)
  }

  return {
    handleBlinkID,
    handleBlinkIDFile,
    icResults,
    icImage,
    loading,
    setCameraFeed,
    setCameraFeedback,
    setUploadFile,
  }
}
