import {
  CommonDialog,
  ContentWrapper,
  Spinner,
  StyledCardContent,
} from '@ifca-ui/neumorphism'
import {
  Avatar,
  Badge,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import DefaultImg from 'assets/images/default-img.png'
import AddressIcon from 'assets/svg/account/address.svg'
import CallIcon from 'assets/svg/account/call.svg'
import MailIcon from 'assets/svg/account/email.svg'
import GoogleMapIcon from 'assets/svg/account/google-map.svg'
import PhoneNoIcon from 'assets/svg/account/phone-no.svg'
import WazeIcon from 'assets/svg/account/waze.svg'
import BillIcon from 'assets/svg/service/bill.svg'
import BookingIcon from 'assets/svg/service/booking.svg'
import CheckOutIcon from 'assets/svg/service/checkout.svg'
import DirectIcon from 'assets/svg/service/directories.svg'
import DoorIcon from 'assets/svg/service/door.svg'
import ExtendStayIcon from 'assets/svg/service/extend-stay.svg'
import LateCheckoutIcon from 'assets/svg/service/late-checkout.svg'
import MyReqIcon from 'assets/svg/service/my-request.svg'
import PackagesRedIcon from 'assets/svg/service/package-redemption.svg'
import ScanIcon from 'assets/svg/service/scan.svg'
import DurationIcon from 'assets/svg/stay-duration.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { useMenuOption } from 'containers/hooks/useMenuOption'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { amtStr } from 'containers/Utils/numFormatter'
import dateFormat from 'dateformat'
import {
  useGaInHouseGuestListingQuery,
  useGaMarketingAdsListingLazyQuery,
  useGuestMovementInsertMutation,
  usePackageRedemptionListingLazyQuery,
  usePaymentUpdateMutation,
} from 'generated/graphql'
import queryString from 'query-string'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import QrReader from 'react-qr-reader'
import { useHistory, useLocation } from 'react-router'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AccountMenuOption } from '../Account/AccountMenuOption'
import { PUBLIC_PATH } from '../Auth/Routes'
import { BOOKING_PATH } from '../Booking/Routes'
import { CheckOutDialog } from './CheckOutDialog'
import { RequestDrawer } from './RequestDrawer'
import { SERVICE_PATH } from './Routes'

// install Swiper modules
SwiperCore.use([Pagination])
interface ServicesProps {
  Drawer: boolean
  Dialog: {
    Open: boolean
    Mode: DialogMode
  }
  QRScannerModal: boolean
  CheckOutDialog?: boolean
  QrScanner?: null | any
  MarketingAdsDialog?: {
    Open: boolean
    Mode: DialogMode
  }
  MarketingAdsIndex?: number
}
export const Services: FC = () => {
  const history = useHistory()
  const location = useLocation()

  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const { anchorEl, handleClose, handleClick } = useMenuOption()
  const PaymentInfo = queryString.parse(location.search)

  const {
    loading,
    data: { GAInHouseGuestListing } = { GAInHouseGuestListing: [] },
  } = useGaInHouseGuestListingQuery({
    variables: {
      GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.ID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.GAInHouseGuestListing?.length === 0) {
        history.push(BOOKING_PATH.BOOKING_LIST)
      } else {
        return console.log('enjoy our services')
      }
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message:
              message === 'Not Authenticated'
                ? 'Login session expired, Please login again.'
                : message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        if (message === 'Not Authenticated') {
          setTimeout(() => {
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            })
            history.push(PUBLIC_PATH.LOGIN)
            localStorage.clear()
          }, 2000)
        }
      })
    },
  })
  const initialState: ServicesProps = {
    Drawer: false,
    QRScannerModal: false,
    CheckOutDialog: false,
    Dialog: {
      Open: false,
      Mode: '',
    },
    QrScanner: null,
    MarketingAdsDialog: {
      Open: false,
      Mode: '',
    },
    MarketingAdsIndex: 0,
  }
  const reducer: Reducer<ServicesProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'HotelDialog':
        return { ...state, Dialog: { Open: true, Mode: 'View' } }
      case 'AdsDialog':
        return { ...state, MarketingAdsDialog: { Open: true, Mode: 'View' } }
      case 'CloseMarketingAdsDialog':
        return { ...state, MarketingAdsDialog: { Open: false, Mode: '' } }
      case 'CloseDialog':
        return { ...state, Dialog: { Open: false, Mode: '' } }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const BookingDetails: any = GAInHouseGuestListing[0]?.Booking
  const HotelDetails: any = GAInHouseGuestListing[0]?.Booking?.Hotel
  const HotelCheckOutDate = dateFormat(HotelDetails?.CheckOutTime, 'hh:MM TT')

  const DirectoriesCount: number =
    GAInHouseGuestListing[0]?.Booking?.Hotel?.Facility?.length
  const HotelAddress: string = `${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.address} ,${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.city} , ${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.state}. ${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.postCode}`
  const RoomDetails: any = GAInHouseGuestListing[0]?.Room
  const RegistrationDetails: any = GAInHouseGuestListing[0]
  const MyRequestCount: number =
    RegistrationDetails?.IncidentalChargesCount +
    RegistrationDetails?.ServiceRequestCount
  const LateCheckOutTime: any =
    dateFormat(RegistrationDetails?.DepartureDate, 'hh:MM TT') !==
    dateFormat(RegistrationDetails?.CheckOutDate, 'hh:MM TT')
      ? dateFormat(RegistrationDetails?.DepartureDate, 'hh:MM TT')
      : HotelCheckOutDate
  const CheckInDate = dateFormat(
    RegistrationDetails?.CheckInDate,
    'dd mmm yyyy'
  )
  const CheckOutDate = dateFormat(
    GAInHouseGuestListing[0]?.CheckOutDate,
    'dd mmm yyyy'
  )

  const TotalOutStandingAmount = RegistrationDetails?.TotalOutStandingAmount

  const handleProgressBarPercent = () => {
    let startDate = new Date(RegistrationDetails?.ArrivalDate).getTime()
    let endDate = new Date(RegistrationDetails?.DepartureDate).getTime()
    let todayDate = new Date().getTime()

    let total = endDate - startDate

    let current = todayDate - startDate

    return (current / total) * 100
  }
  const [
    PackgeRedemQuery,
    { data: { PackageRedemptionListing } = { PackageRedemptionListing: [] } },
  ] = usePackageRedemptionListingLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [
    MarketingAdsQuery,
    { data: { GAMarketingAdsListing } = { GAMarketingAdsListing: [] } },
  ] = useGaMarketingAdsListingLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const handleScan = data => {
    if (data) {
      dispatch({
        type: 'QrScanner',
        payload: data,
      })
    }
  }
  const TotalPax = PackageRedemptionListing?.reduce((acc, curr) => {
    return acc + curr.Total
  }, 0)
  const TotalRedeemed = PackageRedemptionListing?.reduce((acc, curr) => {
    return acc + curr.Redeemed
  }, 0)
  const handleError = err => {
    console.log(err)
  }

  const [PaymentUpdate] = usePaymentUpdateMutation({
    variables: {
      CheckOutID: localStorage.getItem('CheckOutID'),

      IsGuestApp:
        localStorage.getItem('isRoomService') === 'true' ? true : false,
      OrderID: PaymentInfo.orderId as string,
      ReceiptNo: localStorage.getItem('FDReceiptNo'),
      HotelID: localStorage.getItem('HotelID'),
      Status:
        (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
        (PaymentInfo.status as string)?.slice(1).toLowerCase(),
    },
    onCompleted: data => {
      if (data.PaymentUpdate) {
        let Status =
          PaymentInfo.status === 'CANCELLED' ? 'Cancelled' : 'Payment'
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: `${Status} Succesfully`,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        history.replace('/services')
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Payment Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        history.replace('/services')
      }
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
      localStorage.removeItem('FDReceiptNo')
      localStorage.removeItem('CheckOutID')
      localStorage.removeItem('HotelID')
      localStorage.removeItem('isRoomService')
    },
  })
  const [GuestMovementInsert] = useGuestMovementInsertMutation({})
  useEffect(() => {
    if (state.QrScanner) {
      dispatch({ type: 'QRScannerModal', payload: false })
      GuestMovementInsert({
        variables: {
          GuestMovementInput: {
            GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.ID,
            HotelID: state.QrScanner?.split('/')[0],
            BookingID: BookingDetails?.ID,
            RefID: state.QrScanner?.split('/')[1],
            Type: state.QrScanner?.split('/')[2],
          },
        },
      }).then(i => {
        if (i.data.GuestMovementInsert) {
          dispatch({ type: 'QRScannerModal', payload: false })
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Check in Successfully',
            },
          })
        } else {
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Check in Fail',
            },
          })
        }
      })
    }
  }, [state.QrScanner])
  useEffect(() => {
    if (PaymentInfo?.orderId) {
      PaymentUpdate()
    }
  }, [])

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'location',
          title: HotelDetails?.HotelName,
          props: {
            onClick: () => dispatch({ type: 'HotelDialog', payload: {} }),
          },
        },
        right: {
          icon: 'more',
          props: {
            onClick: (e: any) => handleClick(e),
            'aria-controls': 'customized-menu',
          },
        },
      },
    })
    // to prevent user click back button in browser
    // https://stackoverflow.com/questions/57655861/prevent-browser-back-button-for-a-specific-page-only-in-react
    if (location.pathname === '/services') {
      window.history.pushState(null, document.title, window.location.href)
      window.addEventListener('popstate', event => {
        window.history.pushState(null, document.title, window.location.href)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HotelDetails])
  useEffect(() => {
    if (GAInHouseGuestListing?.length > 0) {
      PackgeRedemQuery({
        variables: {
          HotelID: HotelDetails?.ID,
          RegistrationID: RegistrationDetails?.ID,
        },
      })
      MarketingAdsQuery({
        variables: {
          HotelID: HotelDetails?.ID,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HotelDetails, RegistrationDetails])

  const HeaderMenu = (
    <AccountMenuOption anchorEl={anchorEl} handleClose={handleClose} />
  )
  const QuickAccessList = [
    {
      icon: DoorIcon,
      label: 'Door',
      props: {
        onClick: () => alert('Not Available.'),
      },
    },
    {
      icon: ScanIcon,
      label: 'Scan',
      props: {
        onClick: () => dispatch({ type: 'QRScannerModal', payload: true }),
      },
    },
    {
      icon: DirectIcon,
      label: 'Directories',
      value: DirectoriesCount,
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.DIRECTORIES, {
            ...HotelDetails,
          }),
      },
    },
    {
      icon: BookingIcon,
      label: 'Checkout',
      props: {
        onClick: () => {
          if (TotalOutStandingAmount > 0) {
            history.push(SERVICE_PATH.CHECKOUT_BILLS, {
              Hotel: HotelDetails,
              Registration: RegistrationDetails,
              TotalOutStandingAmount: TotalOutStandingAmount,
            })
            // RootDispatch({
            //   type: 'OnSnackBar',
            //   payload: {
            //     ...RootState.OnSnackBar,
            //     Open: true,
            //     Message: `Outstanding Amount $${amtStr(
            //       TotalOutStandingAmount
            //     )}, Please proceed to payment.`,
            //   },
            // })
          } else {
            dispatch({ type: 'CheckOutDialog', payload: true })
          }
        },
      },
    },
  ]
  const ServicesList = [
    {
      Icon: MyReqIcon,
      Title: 'My Request',
      Info: (
        <>
          <span className="fw-medium m-r-4">{MyRequestCount} </span> Pending
          Request.{' '}
        </>
      ),
      props: {
        onClick: () => dispatch({ type: 'Drawer', payload: true }),
      },
    },
    {
      Icon: PackagesRedIcon,
      Title: 'Package Redemption',
      Info: (
        <>
          <span className="fw-medium m-r-4"> {TotalPax || 0}</span>
          Pax |{' '}
          <span className="fw-medium m-l-4 m-r-4">{TotalRedeemed || 0}</span>
          Redeemed{' '}
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.PACKAGE_REDEMPTION, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
          }),
      },
    },
    {
      Icon: LateCheckoutIcon,
      Title: 'Late Checkout',
      Info: (
        <>
          <AccessTimeOutlinedIcon />
          <span className="fw-medium m-r-4"> {LateCheckOutTime}</span>
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.LATE_CHECKOUT, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
          }),
      },
    },
    {
      Icon: ExtendStayIcon,
      Title: 'Extend Stay',
      Info: (
        <>
          <span className="fw-medium m-r-4">{CheckInDate}</span>
          to
          <span className="fw-medium m-l-4  m-r-4">{CheckOutDate}</span>
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.EXTEND_STAY, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
          }),
      },
    },
    {
      Icon: BillIcon,
      Title: 'Bills',
      Info: (
        <>
          <MonetizationOnIcon />
          <span className="fw-medium m-r-4">
            {' '}
            {amtStr(TotalOutStandingAmount)}
          </span>
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.BILLS, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
            TotalOutStandingAmount: TotalOutStandingAmount,
          }),
      },
    },
    {
      Icon: CheckOutIcon,
      Title: 'Booking',
      Info: <>No Advance Booking.</>,
      props: {
        onClick: () => history.push(BOOKING_PATH.BOOKING_LIST_FROM_SERVICE),
      },
    },
  ]
  const QRReader = (
    <Modal
      open={state.QRScannerModal}
      onClose={() => dispatch({ type: 'QRScannerModal', payload: false })}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <QrReader
        delay={300}
        onError={err => handleError(err)}
        onScan={data => handleScan(data)}
        className="qr-reader"
        style={{ width: '100%' }}
      />
    </Modal>
  )
  const Promo = GAMarketingAdsListing?.map(x => x.Gallery?.ImageURL)
  const MarketingAdsDialog = (
    <CommonDialog
      open={state.MarketingAdsDialog.Open}
      onClose={() => {
        dispatch({
          type: 'CloseMarketingAdsDialog',
          payload: {},
        })
      }}
      sections={{
        content: (
          <div className="room-info-wrapper">
            <Swiper
              pagination={true}
              className="promo-swiper"
              initialSlide={state.MarketingAdsIndex}
              onSlideChange={e =>
                dispatch({
                  type: 'MarketingAdsIndex',
                  payload: e.activeIndex,
                })
              }
            >
              {Promo.map((v: string, i: number) => (
                <SwiperSlide key={i}>
                  <img src={v} alt={v} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="mdLabel text-noflow m-t-10">
              {GAMarketingAdsListing[state.MarketingAdsIndex]?.Description}
            </div>
          </div>
        ),
        footer: {
          children: (
            <div className="single-button">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="showmore-btn"
                onClick={() =>
                  history.push(SERVICE_PATH.MARKETING_ADS, {
                    ...GAMarketingAdsListing[state.MarketingAdsIndex],
                  })
                }
              >
                Show more
              </Button>
            </div>
          ),
        },
      }}
    />
  )

  return (
    <>
      <ContentWrapper>
        {loading && <Spinner />}
        <div className="room-info-wrapper">
          <Swiper pagination={true} className="promo-swiper">
            {Promo.map((v: string, i: number) => (
              <SwiperSlide key={i}>
                <img
                  src={v}
                  alt={v}
                  onClick={() => {
                    dispatch({ type: 'AdsDialog', payload: {} })
                    dispatch({
                      type: 'MarketingAdsIndex',
                      payload: i,
                    })
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <StyledCardContent>
            <Grid container justify="flex-start" className="my-room-info">
              <Grid item xs={3}>
                <ListItemAvatar>
                  <Avatar className="svg-icon" src={ExtendStayIcon} />
                </ListItemAvatar>
              </Grid>

              <Grid item xs={9} className="room-content margin-auto">
                <div className="flex-width m-b-8">
                  <div className=" flex-space room-no shadow fs-18 fw-bold">
                    {RoomDetails?.RoomNo}
                  </div>
                  <>
                    <div className="fw-500 duration-icon fs-12 color-text">
                      <img src={DurationIcon} alt="" />{' '}
                      {dateFormat(
                        RegistrationDetails?.DepartureDate,
                        'dd mmm yyyy'
                      )}
                    </div>
                    <div className=" fw-500 time-label">
                      {dateFormat(
                        RegistrationDetails?.DepartureDate,
                        'hh:MM TT'
                      )}
                    </div>
                  </>
                </div>
                <div className="progress ">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${
                        handleProgressBarPercent() > 100
                          ? 100
                          : handleProgressBarPercent()
                      }%`,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="b-white m-b-14 m-t-14" />
            <Grid
              container
              justify="flex-start"
              className="text-align-center quick-access-section"
            >
              {QuickAccessList?.map((x, i) => (
                <Grid item xs={3} key={i} {...x.props}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    color="primary"
                    badgeContent={x.value}
                  />
                  <div className="card">
                    {/* {v.icon()} */}
                    <img
                      src={x.icon}
                      width={35}
                      height={35}
                      srcSet={x.icon}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <div className="fs-12 fw-medium label">{x.label}</div>
                </Grid>
              ))}
            </Grid>
          </StyledCardContent>
        </div>

        <List className="core-list">
          {ServicesList?.map((el, i) => (
            <ListItem key={i} {...el.props}>
              <ListItemAvatar>
                <Avatar className="svg-icon" src={el.Icon} />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <span className="xsTitle fw-500 flex-space">
                      {el.Title}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc flex-space icon-text">{el.Info}</span>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
      {HeaderMenu}
      <CommonDialog
        open={state.Dialog.Open}
        onClose={() => dispatch({ type: 'CloseDialog', payload: {} })}
        sections={{
          content: (
            <>
              <div className="hotel-logo p-t-10 p-b-10">
                <Avatar
                  alt="companylogo"
                  src={HotelDetails?.LogoImage?.ImageURL || DefaultImg}
                  variant="circle"
                />
              </div>
              <div className="hotel-info m-t-8 m-b-8">
                <div className="icon-text desc fw-500 label">
                  <img src={AddressIcon} alt="phone" width={14} height={14} />{' '}
                  {HotelAddress}
                </div>
              </div>
              <div className="hotel-info m-t-8 m-b-16">
                <div className="icon-text desc fw-500 label">
                  <img src={PhoneNoIcon} alt="phone" width={14} height={14} />
                  {HotelDetails?.Contact?.PhoneNo}
                </div>
              </div>
              <Grid
                container
                justify="center"
                alignItems="center"
                className="help-info  m-b-8"
              >
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(`https://waze.com/ul?q=${HotelAddress}`)
                  }
                >
                  <Avatar alt="companylogo" src={WazeIcon} variant="rounded" />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(
                      `https://www.google.com/maps/dir/?api=1&origin=${HotelAddress}`
                    )
                  }
                >
                  <Avatar
                    alt="companylogo"
                    src={GoogleMapIcon}
                    variant="rounded"
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(`tel:${HotelDetails?.Contact?.PhoneNo}`)
                  }
                >
                  <Avatar alt="companylogo" src={CallIcon} variant="rounded" />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(
                      `mailto:${HotelDetails?.Contact?.BusinessEmail}`
                    )
                  }
                >
                  <Avatar alt="companylogo" src={MailIcon} variant="rounded" />
                </Grid>
              </Grid>
            </>
          ),
        }}
      />
      <CheckOutDialog
        state={state}
        dispatch={dispatch}
        RootState={RootState}
        RootDispatch={RootDispatch}
        HotelDetails={HotelDetails}
        RegistrationDetails={RegistrationDetails}
        RoomDetails={RoomDetails}
      />
      {QRReader}
      <RequestDrawer
        state={state}
        dispatch={dispatch}
        HotelDetails={HotelDetails}
        RegistrationDetails={RegistrationDetails}
        RoomDetails={RoomDetails}
      />
      {MarketingAdsDialog}
    </>
  )
}
