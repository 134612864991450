/* eslint-disable array-callback-return */
import { CommonDialog } from '@ifca-ui/neumorphism'
import { Avatar, Button, ListItemAvatar } from '@material-ui/core'
import ExtendStayIcon from 'assets/svg/service/extend-stay.svg'
import dateFormat from 'dateformat'
import { useCheckOutMutation } from 'generated/graphql'
import React from 'react'
import { useHistory } from 'react-router'
import { SERVICE_PATH } from './Routes'

export const CheckOutDialog = ({
  state,
  dispatch,
  RootState,
  RootDispatch,
  RoomDetails,
  RegistrationDetails,
  HotelDetails,
}) => {
  const history = useHistory()
  const [CheckOut, { loading }] = useCheckOutMutation({
    variables: {
      RoomID: RoomDetails?.ID,
      HotelID: HotelDetails?.ID,
      RegistrationIDs: [RegistrationDetails?.ID],
      BookingID: RegistrationDetails?.Booking?.ID,
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.CheckOut) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'CheckOut Succesfully',
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          dispatch({ type: 'CloseDialog', payload: {} })
          history.push(SERVICE_PATH.RATING, {
            RegistrationDetails: RegistrationDetails,
            HotelDetails: HotelDetails,
          })
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Check Out Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
  })
  return (
    <CommonDialog
      open={state.CheckOutDialog}
      sections={{
        content: (
          <>
            <div className=" text-align-center color-text mdDesc m-b-10">
              Confirm Checkout?
            </div>
            <div
              className="m-b-10"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="my-room-info">
                <ListItemAvatar>
                  <Avatar className="svg-icon" src={ExtendStayIcon} />
                </ListItemAvatar>
              </div>
              <div
                style={{
                  margin: 'auto 6px',
                }}
              >
                <div className="  room-no shadow fs-12 fw-medium">Room</div>
                <div className="  room-no shadow fs-30 fw-bold">
                  {RoomDetails?.RoomNo}
                </div>
              </div>
            </div>
            <div className=" text-align-center color-text-2 fs-12 fw-regular m-b-10">
              {dateFormat(new Date(), 'dd mmm yyyy hh:MM TT')}
            </div>
          </>
        ),
        footer: {
          children: (
            <div className="double-button">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() => {
                  dispatch({
                    type: 'CheckOutDialog',
                    payload: false,
                  })
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={loading}
                className="action-btn"
                onClick={() => {
                  CheckOut()
                }}
              >
                Confirm
              </Button>
            </div>
          ),
        },
      }}
    />
  )
}
