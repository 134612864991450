import { Button, IconButton, Popover } from '@material-ui/core'
import { Remove, Add } from '@material-ui/icons'
import React from 'react'

export const FilterOptions = props => {
  const { id, open, state, dispatch, handleClose } = props

  return (
    <Popover
      className="filter-popover"
      id={id}
      open={open}
      anchorEl={state.AnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          width: '70%',
          maxWidth: '70%',
          padding: '12px',
        },
      }}
    >
      <div className="flex-width m-b-12">
        <span className="xsTitle flex-space" style={{ margin: 'auto' }}>
          Room
        </span>

        <span className="desc plus-minus-section ">
          <IconButton
            aria-label="delete"
            size="small"
            disabled={state.Rooms === 0}
            onClick={() => dispatch({ type: 'RoomsDecrement', payload: {} })}
          >
            <Remove />
          </IconButton>

          <span className="xsTitle counts">{state.Rooms}</span>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => dispatch({ type: 'RoomsIncrement', payload: {} })}
          >
            <Add />
          </IconButton>
        </span>
      </div>
      <div className="flex-width">
        <span className="xsTitle flex-space" style={{ margin: 'auto' }}>
          Adults
        </span>

        <span className="desc plus-minus-section ">
          <IconButton
            aria-label="delete"
            size="small"
            disabled={state.Adults === 0}
            onClick={() => dispatch({ type: 'AdultsDecrement', payload: {} })}
          >
            <Remove />
          </IconButton>

          <span className="xsTitle counts">{state.Adults}</span>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => dispatch({ type: 'AdultsIncrement', payload: {} })}
          >
            <Add />
          </IconButton>
        </span>
      </div>
    </Popover>
  )
}
