import { ContentWrapper, Spinner } from '@ifca-ui/neumorphism'
import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
} from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import DefaultImg from 'assets/images/default-img.png'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { amtStr } from 'containers/Utils/numFormatter'
import { useIncidentalChargeListingByHotelQuery } from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { SERVICE_PATH } from '../Routes'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

interface StateProps {
  OrderMenu?: any
}
interface Props {
  ReqType?: string
}
export const Order: FC<Props> = ({ ReqType }) => {
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    loading,
    data: { IncidentalChargeListingByHotel } = {
      IncidentalChargeListingByHotel: [],
    },
  } = useIncidentalChargeListingByHotelQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  const initialState: StateProps = {
    OrderMenu: [],
  }
  const reducer: Reducer<StateProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    if (IncidentalChargeListingByHotel?.length > 0) {
      dispatch({
        type: 'OrderMenu',
        payload:
          localState.OrderMenu ||
          IncidentalChargeListingByHotel?.filter(x =>
            ReqType === 'FnB' ? x.IsFnB : x.IsEShop
          ).map(el => {
            return {
              ...el,
              Quantity: 0,
              TotalItemPrice: 0,
            }
          }),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IncidentalChargeListingByHotel])
  const handleMinus = index => {
    const newArr: any = state.OrderMenu
    let selected = newArr[index]
    selected.Quantity = state.OrderMenu[index]?.Quantity - 1
    selected.TotalItemPrice =
      state.OrderMenu[index]?.Amount * state.OrderMenu[index]?.Quantity
    dispatch({
      type: 'OrderMenu',
      payload: newArr,
    })
  }
  const handlePlus = index => {
    const newArr: any = state.OrderMenu
    let selected = newArr[index]
    selected.Quantity = state.OrderMenu[index]?.Quantity + 1
    selected.TotalItemPrice =
      state.OrderMenu[index]?.Amount * state.OrderMenu[index]?.Quantity
    dispatch({
      type: 'OrderMenu',
      payload: newArr,
    })
  }
  const TotalOrderSelected = state.OrderMenu.reduce((acc, curr) => {
    return acc + curr.Quantity
  }, 0)
  const OrderSelected = state.OrderMenu?.filter(x => x.Quantity !== 0)
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'close',
          title: 'New Order',
          props: {
            onClick: () =>
              history.push(
                ReqType === 'FnB'
                  ? SERVICE_PATH.REQ_ROOM_SERVICE
                  : SERVICE_PATH.REQ_ONLINE_SHOP,
                { ...localState, OrderMenu: null }
              ),
          },
        },
        right: {
          icon: 'cart',
          value: TotalOrderSelected > 0 ? TotalOrderSelected : null,
          props: {
            onClick: () =>
              history.push(
                ReqType === 'FnB'
                  ? SERVICE_PATH.REQ_ROOM_SERVICE_FORM_SUMMARY
                  : SERVICE_PATH.REQ_ONLINE_SHOP_FORM_SUMMARY,
                {
                  ...localState,
                  OrderSelected: OrderSelected,
                  OrderMenu: state.OrderMenu,
                }
              ),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TotalOrderSelected])
  return (
    <>
      {loading && <Spinner />}{' '}
      <ContentWrapper>
        <GridList cellHeight={180} spacing={15} className="core-image-list">
          {state?.OrderMenu?.map((item: any, index: number) => (
            <GridListTile
              className="order"
              key={index}
              //   onClick={() =>
              //     history.push(SERVICE_PATH.DIRECTORIES_DETAIL, {
              //       // Detail: item,
              //       // ...HotelDetails,
              //     })
              //   }
            >
              <img
                src={item?.Gallery?.ImageURL || DefaultImg}
                alt={item.Description}
              />
              <GridListTileBar
                className="order"
                title={item.Description}
                subtitle={
                  <span className="icon-text">
                    {' '}
                    <MonetizationOnIcon /> {amtStr(item.Amount)}
                  </span>
                }
                actionIcon={
                  <div className="plus-minus">
                    <IconButton
                      aria-label={`info about ${item.Description}`}
                      disabled={state.OrderMenu[index]?.Quantity < 1}
                      onClick={() => {
                        handleMinus(index)
                      }}
                    >
                      <Remove />
                    </IconButton>
                    <div className="mdLabel count">{item.Quantity}</div>
                    <IconButton
                      aria-label={`info about ${item.Description}`}
                      onClick={() => {
                        handlePlus(index)
                      }}
                    >
                      <Add />
                    </IconButton>
                  </div>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </ContentWrapper>
    </>
  )
}
