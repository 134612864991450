import { ContentWrapper, Spinner, StyledFooter } from '@ifca-ui/neumorphism'
import { TimePicker } from '@material-ui/pickers'
import { RedirectUrl } from 'containers/App/Client'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { amtNumStr, amtStr } from 'containers/Utils/numFormatter'
import { getHours, isAfter, isBefore, isSameHour, setHours } from 'date-fns'
import { format } from 'date-fns/esm'
import {
  ChargeType,
  PaymentClass,
  PaymentStatus,
  TaxIdentifier,
  useComputeTaxbyHotelLazyQuery,
  useIsPaymentGateWayHotelQuery,
  useLateCheckOutChargeListingQuery,
  useLateCheckOutInsertMutation,
  useOnlinePaymentMutation,
} from 'generated/graphql'
import { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { PaymentDialog } from './PaymentDialog'
import { SERVICE_PATH } from './Routes'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

// import { setHours } from 'date-fns'
interface Props {
  Hour?: any
  HourSelected?: any
  PaymentDialog?: {
    Open?: boolean
    Mode?: DialogMode
  }
}
export const LateCheckOut: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    data: IsPaymentGateWayHotel = { IsPaymentGateWayHotel: null },
  } = useIsPaymentGateWayHotelQuery({
    fetchPolicy: 'no-cache',
    variables: {
      HotelID: localState?.Hotel?.ID,
    },
  })
  const {
    data: { LateCheckOutChargeListing } = { LateCheckOutChargeListing: [] },
  } = useLateCheckOutChargeListingQuery({
    variables: { HotelID: localState?.Hotel?.ID },
    fetchPolicy: 'no-cache',
  })
  const [
    ComputeTaxbyHotelQuery,
    {
      data: { ComputeTaxbyHotel } = {
        ComputeTaxbyHotel: {
          DiscountAmount: 0,
          BaseAmount: 0,
          TaxAmount: 0,
          ServiceCharge: 0,
          TotalAmount: 0,
        },
      },
    },
  ] = useComputeTaxbyHotelLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const initialState: Props = {
    Hour: new Date().setHours(12, 0),
    HourSelected: null,
    PaymentDialog: {
      Open: false,
      Mode: '',
    },
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'PaymentDialog':
        return { ...state, PaymentDialog: { Open: true, Mode: 'Payment' } }
      case 'ClosePaymentDialog':
        return { ...state, PaymentDialog: { Open: false, Mode: '' } }
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Late Checkout',
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkOutDateWithoutTime = format(
    new Date(localState?.Registration?.CheckOutDate),
    'dd mmm yyyy'
  )

  let HourHidden = isAfter(new Date(state.Hour), new Date().setHours(12, 0))
  let AfterSelectedHour = isAfter(
    new Date(state.Hour),
    new Date().setHours(18, 0)
  )
  let IsSameHour = isSameHour(new Date(state.Hour), new Date().setHours(12, 0))

  function DisableTimeChecking(date1, date2) {
    for (let i = 6; i > 0; i--) {
      if (isBefore(setHours(new Date(date1), i), new Date(date2)) === true) {
        return i
      }
    }
  }
  const TotalHourAmount = !state.HourSelected?.IsActive
    ? amtStr(0)
    : isBefore(
        setHours(
          new Date(checkOutDateWithoutTime),
          Number(format(new Date(state.Hour), 'h'))
        ),
        new Date(localState?.Registration?.CheckOutDate)
      )
    ? amtStr(0)
    : DisableTimeChecking(
        checkOutDateWithoutTime,
        localState?.Registration?.CheckOutDate
      ) === undefined
    ? amtStr(state.HourSelected?.Amount)
    : amtStr(
        state.HourSelected?.Amount -
          LateCheckOutChargeListing[
            DisableTimeChecking(
              checkOutDateWithoutTime,
              localState?.Registration?.CheckOutDate
            ) - 1
          ]?.Amount
      )
  useEffect(() => {
    if (TotalHourAmount) {
      ComputeTaxbyHotelQuery({
        variables: {
          input: {
            HotelID: localState?.Hotel?.ID,
            TaxIdentifier: TaxIdentifier['Room'],
            Tax: true,
            ServiceCharge: true,
            TrxAmount: Number(TotalHourAmount),
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TotalHourAmount])

  const [OnlinePayment] = useOnlinePaymentMutation()

  const [LateCheckOutInsert, { loading }] = useLateCheckOutInsertMutation({})
  const Variables = mode => {
    return {
      hour: getHours(state.Hour) - 12,
      input: {
        BookingID: localState?.Registration?.Booking?.ID,
        HotelID: localState?.Hotel?.ID,
        PaymentType: mode,
        Amount: ComputeTaxbyHotel?.TotalAmount,
        ChargeType: ChargeType['LateCheckOut'],
        ServiceCharge: ComputeTaxbyHotel?.ServiceCharge,
        TaxAmount: ComputeTaxbyHotel?.TaxAmount,
        BaseAmount: ComputeTaxbyHotel?.BaseAmount,
        PaymentClass: PaymentClass['Room'],
        RegistrationID: localState?.Registration?.ID,
        PaymentStatus: PaymentStatus['Pending'],
        AccountID: localState?.Hotel?.AccountID,
      },
    }
  }
  const handleChargeToRoom = () => {
    dispatch({ type: 'ClosePaymentDialog', payload: {} })

    LateCheckOutInsert({
      variables: Variables('Room'),
    }).then(i => {
      if (i.data.LateCheckOutInsert !== 'fail') {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Late Checkout Successfully',
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(SERVICE_PATH.LIST)
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Late Checkout Fail',
          },
        })
      }
    })
  }
  const handleOnlinePayment = () => {
    dispatch({ type: 'ClosePaymentDialog', payload: {} })

    LateCheckOutInsert({
      variables: Variables('Online'),
    }).then(i => {
      if (i.data.LateCheckOutInsert !== 'fail') {
        localStorage.setItem('FDReceiptNo', i.data.LateCheckOutInsert)
        OnlinePayment({
          variables: {
            redirectUrl: `${RedirectUrl}/services/redirect/:status`,
            PayAmount:
              process.env.REACT_APP_API_URL === 'prod-build' ||
              process.env.REACT_APP_API_URL === 'uat-build'
                ? Number(ComputeTaxbyHotel?.TotalAmount)
                : 1,
            Detail: `Late Checkout Payment`,
            Title: ` Late Checkout Payment`,
            HotelID: localState?.Hotel?.ID,

            ReceiptNo: i.data.LateCheckOutInsert || null,
          },
        }).then(i => {
          if (i.data.OnlinePayment?.code === 'SUCCESS') {
            localStorage.setItem(
              'CheckOutID',
              i.data.OnlinePayment.item.checkoutId
            )
            localStorage.setItem('HotelID', localState?.Hotel?.ID)
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Redirecting to payment gateway...',
              },
            })
            window.location.assign(i.data.OnlinePayment.item.url)
          } else {
            i.data.OnlinePayment.error.code !== ''
              ? RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: i.data.OnlinePayment.error.message,
                  },
                })
              : RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: 'Insert Fail.',
                  },
                })
          }
        })
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Late Checkout Fail',
          },
        })
      }
    })
  }
  return (
    <>
      {loading && <Spinner />}
      <ContentWrapper>
        <div className="late-checkout-clock">
          <div className="hours"> {format(new Date(state.Hour), 'hh')}</div>
          <div className="hours-tt"> PM</div>
          <TimePicker
            autoOk
            disableToolbar
            variant="static"
            views={['hours']}
            orientation="portrait"
            // openTo="hours"
            value={state.Hour}
            onChange={date => {
              dispatch({
                type: 'Hour',
                payload: new Date(date).setMinutes(0),
              })
              dispatch({
                type: 'HourSelected',
                payload:
                  LateCheckOutChargeListing[
                    getHours(new Date(date).setMinutes(0)) - 13
                  ],
              })
            }}
          />
        </div>
        <div className="text-align-center">
          <div className=" fw-semibold fs-18 m-b-6 ">New Checkout Time</div>
          {HourHidden ? (
            <>
              <div className=" mdLabel color-text-2 m-b-6 ">
                <span className="m-r-2">
                  {format(new Date(state.Hour), 'h')}
                </span>{' '}
                Hours
              </div>
              <div className=" fs-20 fw-bold  m-b-30">
                {format(new Date(state.Hour), 'hh')}:00 PM
              </div>
              <div className="text-align-center ">
                <div className=" mdLabel  m-b-6 color-text-2 ">Total</div>
                <div
                  className=" fs-25 fw-bold color-blue m-b-4"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MonetizationOnIcon /> {TotalHourAmount}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </ContentWrapper>
      {/* {HourHidden ? (
        <div className="text-align-center total-section">
          <div className=" mdLabel  m-b-4 ">Total</div>
          <div className=" fs-25 fw-bold  m-b-4">$ {TotalHourAmount}</div>
        </div>
      ) : null} */}
      {/* <StyledFooter
        sections={{
          option: [
            {
              props: {
                onClick: () => handleChargeToRoom(),
                disabled: AfterSelectedHour || IsSameHour,
              },

              name: 'Charge to Room',
            },
            {
              props: {
                onClick: () => handleOnlinePayment(),
                disabled:
                  !IsPaymentGateWayHotel || AfterSelectedHour || IsSameHour,
              },

              name: 'Pay Now',
            },
          ],
        }}
      /> */}
      {Number(TotalHourAmount) !== 0 && (
        <StyledFooter
          sections={{
            isSingle: true,
            option: [
              {
                props: {
                  onClick: () =>
                    dispatch({ type: 'PaymentDialog', payload: {} }),
                  disabled: AfterSelectedHour || IsSameHour,
                },
                name: 'Confirm',
              },
            ],
          }}
        />
      )}
      <PaymentDialog
        state={state}
        dispatch={dispatch}
        IsPaymentGateWayHotel={IsPaymentGateWayHotel}
        ChargeToRoom={() => handleChargeToRoom()}
        OnlinePayment={() => handleOnlinePayment()}
      />
    </>
  )
}
