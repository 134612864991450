import { Grid, SwipeableDrawer } from '@material-ui/core'
import OnlineShopicon from 'assets/svg/service/online-shop.svg'
import RoomServiceIcon from 'assets/svg/service/room-services.svg'
import ServiceReqIcon from 'assets/svg/service/service-req.svg'
import React from 'react'
import { useHistory } from 'react-router'
import { SERVICE_PATH } from './Routes'

export const RequestDrawer = ({
  state,
  dispatch,
  RoomDetails,
  RegistrationDetails,
  HotelDetails,
}) => {
  const history = useHistory()

  const handleRedirect = mode => {
    let State = {
      Hotel: HotelDetails,
      Room: RoomDetails,
      Registration: RegistrationDetails,
    }
    if (mode === 'ServiceRequest') {
      history.push(SERVICE_PATH.REQ_SERVICE_REQUEST, {
        ...State,
      })
    }
    if (mode === 'RoomService') {
      history.push(SERVICE_PATH.REQ_ROOM_SERVICE, {
        ...State,
      })
    }
    if (mode === 'OnlineShop') {
      history.push(SERVICE_PATH.REQ_ONLINE_SHOP, {
        ...State,
      })
    }
  }
  return (
    <SwipeableDrawer
      className="drawer-wrapper"
      anchor={'bottom'}
      open={state.Drawer}
      onClose={() => dispatch({ type: 'Drawer', payload: false })}
      onOpen={() => dispatch({ type: 'Drawer', payload: true })}
    >
      <div className="color-divider" />
      <Grid
        className="m-t-15 m-b-15 text-align-center"
        container
        justify="flex-start"
        spacing={3}
      >
        <Grid item xs={4} onClick={() => handleRedirect('ServiceRequest')}>
          <div className="request-section m-b-12">
            <img
              src={ServiceReqIcon}
              width={45}
              height={45}
              loading="lazy"
              alt=""
            />
          </div>
          <div className="fs-12 fw-medium  color-text">Service Request</div>
        </Grid>
        <Grid item xs={4} onClick={() => handleRedirect('RoomService')}>
          <div className="request-section m-b-12">
            <img
              src={RoomServiceIcon}
              width={45}
              height={45}
              loading="lazy"
              alt=""
            />
          </div>
          <div className="fs-12 fw-medium  color-text">Room Service</div>
        </Grid>
        <Grid item xs={4} onClick={() => handleRedirect('OnlineShop')}>
          <div className="request-section m-b-12">
            <img
              src={OnlineShopicon}
              width={45}
              height={45}
              loading="lazy"
              alt=""
            />
          </div>
          <div className="fs-12 fw-medium   color-text">Online Shop</div>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  )
}
