import { AuthWrapper } from '@ifca-ui/neumorphism'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Box,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import AppLogo from 'assets/images/hotelx.png'
import AuthBackground from 'assets/images/auth-background.jpg'
import { ReactComponent as CheckBoxIcon } from 'assets/svg/CheckBoxIcon.svg'
// import AppContext, { AppContextProps } from "containers/Context/Context";
import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  useGaInHouseGuestListingLazyQuery,
  useGuestLoginMutation,
} from '../../../generated/graphql'
import { PUBLIC_PATH } from './Routes'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import * as yup from 'yup'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { BOOKING_PATH } from '../Booking/Routes'
import { SERVICE_PATH } from '../Services/Routes'
import PhoneInput from 'react-phone-input-2'

type LoginProps = {
  Email?: string
  Password?: string
  MobileNo?: string
  ShowPassword?: boolean
}
export const LoginSchema = yup.object().shape({
  Email: yup.string(),
  MobileNo: yup.string(),
  Password: yup.string().required('Password is required'),
})
export const Login: FC<LoginProps> = () => {
  const history = useHistory()
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const [IsMobileNo, setIsMobileNo] = useState(true)
  const [CheckInHouseList] = useGaInHouseGuestListingLazyQuery({
    fetchPolicy: 'no-cache',

    onCompleted: data => {
      if (data.GAInHouseGuestListing?.length === 0) {
        history.push(BOOKING_PATH.BOOKING_LIST)
      } else {
        history.push(SERVICE_PATH.LIST)
      }
      RootDispatch({
        type: 'CloseSnackBar',
        payload: {},
      })
    },
  })
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<LoginProps>({
    defaultValues: {},
    mode: 'onBlur',
    resolver: yupResolver(LoginSchema),
  })
  const [GuestLogin, { loading }] = useGuestLoginMutation({
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.GuestLogin) {
        CheckInHouseList({
          variables: {
            GuestID: data.GuestLogin.ID,
          },
        })
        localStorage.setItem('GuestLoginInfo', JSON.stringify(data.GuestLogin))
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Login Succesfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })

        // setTimeout(() => {
        //   RootDispatch({
        //     type: 'CloseSnackBar',
        //     payload: {},
        //   })
        //   return GAInHouseGuestListing?.length !== 0
        //     ? history.push(SERVICE_PATH.LIST)
        //     : history.push(BOOKING_PATH.BOOKING_LIST)
        // }, 2000)
      }
    },
  })

  const ValidateEmail = email => {
    var rgx = /\S+@\S+\.\S+/
    return rgx.test(email)
  }
  const onSubmit = data => {
    GuestLogin({
      variables: {
        Email: !IsMobileNo && ValidateEmail(data.Email) ? data.Email : null,
        Login: !IsMobileNo && !ValidateEmail(data.Email) ? data.Email : null,
        Password: data.Password,
        MobileNo: IsMobileNo ? `+${data.MobileNo}` : null,
      },
    })
  }
  useEffect(() => {
    RootDispatch({
      type: 'OnLoading',
      payload: loading,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  return (
    <AuthWrapper bgImageURL={AuthBackground} appLogoURL={AppLogo}>
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <div className="form-wrapper">
          {!IsMobileNo ? (
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Login/Email"
                  margin="normal"
                  fullWidth
                  helperText={errors?.Email?.message}
                  error={errors?.Email ? true : false}
                  // type="email"
                />
              )}
              control={control}
              name="Email"
            />
          ) : (
            <>
              <Controller
                render={({ field }) => (
                  <PhoneInput
                    containerClass="contact-input-new"
                    inputProps={{
                      name: 'Mobile No.',
                      required: true,
                    }}
                    specialLabel="Mobile No. *"
                    {...field}
                    country={'my'} //Country prefix
                  />
                )}
                name="MobileNo"
                control={control}
              />
            </>
          )}
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue('ShowPassword', !getValues('ShowPassword'))
                        }
                      >
                        {watch('ShowPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.Password?.message}
                error={errors?.Password ? true : false}
                autoComplete="off"
                fullWidth
                label="Password"
                type={watch('ShowPassword') ? 'text' : 'password'}
              />
            )}
            name="Password"
            control={control}
          />
          <div className="other-auth">
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedB"
                  color="primary"
                  checkedIcon={
                    <SvgIcon component={CheckBoxIcon} viewBox="0 0 30 30" />
                  }
                />
              }
              label="Remember Me"
            />
            <span
              className="forget-password"
              onClick={() => history.push(PUBLIC_PATH.FORGOT_PASSWORD)}
            >
              Forget Password?
            </span>
          </div>
          <Box
            className="desc"
            width="100%"
            textAlign="center"
            style={{
              textDecoration: 'underline',
            }}
            onClick={() => setIsMobileNo(!IsMobileNo)}
          >
            Login with {IsMobileNo ? 'Login ID / Email' : 'Mobile No'}
          </Box>{' '}
          <div className="text-align-center">
            <span className="desc m-r-4">I'm New to HotelX</span>
            <span
              className="desc color-orange click-text"
              onClick={() => history.push(PUBLIC_PATH.REGISTER)}
            >
              Click here
            </span>
          </div>
        </div>

        <div className="button-box">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="login-btn"
            fullWidth
          >
            Login
          </Button>
        </div>
      </form>
    </AuthWrapper>
  )
}
