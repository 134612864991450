import { MainHeader } from '@ifca-ui/neumorphism'
import { Box, IconButton, Modal } from '@material-ui/core'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { ReactComponent as ScanBorderIcon } from 'assets/images/scan-border.svg'
import React from 'react'
import Webcam from 'react-webcam'
export const ScanIDModal = ({
  open,
  onClose,
  webcamRef,
  captureID,
  uploadID,
}) => {
  const videoConstraints = {
    // facingMode: { exact: 'environment' },
    facingMode: 'environment',
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className="video-section"
        component="section"
        height="100vh"
        display="flex"
        alignItems="center"
        width={'100%'}
        maxWidth={{ sm: '1200px' }}
        margin={{ sm: '0 auto' }}
      >
        <MainHeader
          sections={{
            left: {
              icon: 'leftArrow',
              title: 'Scan ID',
              props: {
                onClick: onClose,
              },
            },
          }}
        />

        <Box
          component="section"
          overflow="hidden"
          position="relative"
          width="100%"
          paddingTop="100%"
          // height="150px"
          display="block"
        >
          <Box
            top="0px"
            left="0px"
            zIndex={100}
            boxSizing="border-box"
            position="absolute"
            width="100%"
            height="100%"
          >
            <Box
              justifyContent="center"
              margin="0 auto"
              display="flex"
              alignItems="center"
              height="100%"
              className="scan-border"
            >
              <ScanBorderIcon />
            </Box>
          </Box>

          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        </Box>
        <Box
          width="100%"
          zIndex={100}
          position="fixed"
          bottom="32px"
          right="0"
          bgcolor="#00000080"
          padding="50px 0px"
        >
          <Box
            display="flex"
            width="100%"
            margin="0 auto"
            justifyContent="space-evenly"
          >
            <IconButton aria-label="delete" onClick={captureID}>
              <RadioButtonCheckedIcon
                style={{ color: '#fff', fontSize: '30px' }}
              />
            </IconButton>
            <IconButton aria-label="delete" onClick={uploadID}>
              <InsertPhotoIcon style={{ color: '#fff', fontSize: '30px' }} />
            </IconButton>
          </Box>
        </Box>

        {/* <StyledFooter
            sections={{
              option: [
                {
                  props: {
                    onClick: () => captureIC(),
                  },
                  name: 'Capture',
                },
                {
                  props: {
                    onClick: () => inputFile.current.click(),
                  },
                  name: 'Choose from gallery',
                },
              ],
            }}
          /> */}
      </Box>
    </Modal>
  )
}
