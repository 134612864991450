/* eslint-disable react-hooks/exhaustive-deps */
import { ContentWrapper, Spinner, StyledFooter } from '@ifca-ui/neumorphism'
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
import DefaultImg from 'assets/images/default-img.png'
import AdultsIcon from 'assets/svg/booking/adult.svg'
import LocationIcon from 'assets/svg/booking/location.svg'
import NoSmokingIcon from 'assets/svg/booking/no-smoking.svg'
import StarIcon from 'assets/svg/booking/rating-star.svg'
import ShareIcon from 'assets/svg/booking/share.svg'
import MoreIcon from 'assets/svg/more.svg'
// import { RedirectUrl } from 'containers/App/Client'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { amtStr } from 'containers/Utils/numFormatter'
import { useHotelPricesByDateRangeQuery } from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import ShowMoreText from 'react-show-more-text'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BOOKING_PATH } from '../Routes'
import { PackageOption } from './PackageOption'

// install Swiper modules
SwiperCore.use([Pagination])
interface Props {
  Drawer: boolean
  DrawerMode?: string
  RoomAvailable: any
  AnchorEl: null | undefined | HTMLElement
  IndexSelected: null | boolean
}
export const HotelDetail: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  console.log(localState)
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  const initialState: Props = {
    Drawer: false,
    DrawerMode: '',
    RoomAvailable: [],
    AnchorEl: null,
    IndexSelected: null,
  }

  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'ShareDrawer':
        return { ...state, Drawer: true, DrawerMode: 'Share' }
      case 'AmenitiesDrawer':
        return { ...state, Drawer: true, DrawerMode: 'Amenities' }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const Open = Boolean(state.AnchorEl)
  const ID = Open ? 'simple-popover' : undefined
  const {
    loading,
    data: { HotelPricesByDateRange } = { HotelPricesByDateRange: null },
  } = useHotelPricesByDateRangeQuery({
    fetchPolicy: 'no-cache',

    variables: {
      CheckIn: localState?.CheckInDate,
      CheckOut: localState?.CheckOutDate,
      HotelID: localState?.HotelDetail?.ID,
    },
  })
  const RedirectUrl = `https://${HotelPricesByDateRange?.OnlineBookingURL}`

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        header: {
          headerMode: 'secondary',
        },
        left: {
          icon: 'leftArrow',
          title: ``,
          props: {
            onClick: () =>
              history.push(BOOKING_PATH.HOTEL_LIST, { ...localState }),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (HotelPricesByDateRange) {
      RootDispatch({
        type: 'HeaderSection',
        payload: {
          header: {
            headerMode: 'secondary',
          },
          left: {
            icon: 'leftArrow',
            title: `${HotelPricesByDateRange?.HotelName}`,
            props: {
              onClick: () =>
                history.push(BOOKING_PATH.HOTEL_LIST, { ...localState }),
            },
          },
        },
      })
    }
    if (HotelPricesByDateRange?.HotelRate?.length > 0) {
      dispatch({
        type: 'RoomAvailable',
        payload: HotelPricesByDateRange?.HotelRate?.filter(
          x =>
            x.TotalPackages.length !== 0 &&
            x.TotalRoom >= localState?.Rooms &&
            x.MaxGuest >= localState?.Adults
        )?.map(x => {
          return {
            ...x,
            TotalSelected: 0,
            TotalPackages: x.TotalPackages.map(el => ({
              ...el,
              TotalNumOfRoom: 0,
            })),
          }
        }),
      })
    }
  }, [HotelPricesByDateRange])
  const DisplayFooter = state.RoomAvailable?.map(x =>
    x.TotalPackages.some(el => el.TotalNumOfRoom)
  ).some(i => i)

  let Results = []
  let GroupResults = []
  let GroupArr = state?.RoomAvailable?.forEach(el => {
    el.TotalPackages?.forEach(el2 => {
      if (el2.TotalNumOfRoom > 0) {
        // for (let i = 0; i < el2?.TotalNumOfRoom; i++) {
        GroupResults.push({
          ...el,
          RatePackages: el2,
          SpecialRequest: '',
          DiscountAmount: 0,
        })
      }
      // }
    })
  })
  let finalArr = state?.RoomAvailable?.forEach(el => {
    el.TotalPackages?.forEach(el2 => {
      if (el2.TotalNumOfRoom > 0) {
        for (let i = 0; i < el2?.TotalNumOfRoom; i++) {
          Results.push({
            ...el,
            RatePackages: el2,
            SpecialRequest: '',
            DiscountAmount: 0,
          })
        }
      }
    })
  })
  console.log(Results)
  console.log(state, 'detail')
  const Amenities =
    state.RoomAvailable?.map((el: any) =>
      el.RoomTypeAmenities?.filter((x: any) => x.IsSelected)
    )?.flat() || []
  const getUniqueAmenities = (arr, key?: string) => {
    return arr
      ? [
          ...(new Map(arr?.map((item: any) => [item[key], item]))?.values() ||
            []),
        ]
      : []
  }
  const ShareDrawer = (
    <SwipeableDrawer
      className="drawer-wrapper"
      anchor={'bottom'}
      open={state.Drawer}
      onClose={() => dispatch({ type: 'Drawer', payload: false })}
      onOpen={() => dispatch({ type: 'Drawer', payload: true })}
    >
      <div className="color-divider" />
      {state.DrawerMode === 'Share' ? (
        <>
          <div className="p-10 icon-text">
            <WhatsappShareButton
              title={HotelPricesByDateRange?.HotelName}
              url={RedirectUrl}
              style={{ display: 'flex' }}
            >
              <WhatsappIcon
                style={{ marginRight: '5px' }}
                size={25}
                round={true}
              />
              <div className="desc fs-12 m-t-4">Share on WhatsApp</div>
            </WhatsappShareButton>
          </div>
          <div className="p-10">
            <FacebookShareButton
              url={RedirectUrl}
              quote={HotelPricesByDateRange?.HotelName}
              style={{ display: 'flex' }}
            >
              <FacebookIcon
                style={{ marginRight: '5px' }}
                size={25}
                round={true}
              />
              <div className="desc fs-12 m-t-4">Share on Facebook</div>
            </FacebookShareButton>
          </div>
        </>
      ) : (
        <Grid
          container
          justify="flex-start"
          className="amenities-wrapper"
          style={{
            height: 350,
          }}
        >
          {getUniqueAmenities(Amenities, 'Name')?.length > 0
            ? (getUniqueAmenities(Amenities, 'Name') || [])?.map(
                (x: any, i) => (
                  <Grid item xs={4} key={i} className="m-b-4">
                    <IconButton size="small">
                      <img
                        src={
                          require(`../../../../` + x?.Gallery?.ImageURL).default
                        }
                        alt="share"
                      />
                    </IconButton>
                    <div
                      className="desc text-overflow "
                      style={{
                        whiteSpace: 'break-spaces',
                      }}
                    >
                      {x?.Name}
                    </div>
                  </Grid>
                )
              )
            : null}
        </Grid>
      )}
    </SwipeableDrawer>
  )
  const AmenitiesList = (
    <Grid container justify="flex-start" className="amenities-wrapper">
      {getUniqueAmenities(Amenities, 'ID')
        ?.slice(0, 4)
        ?.map((x: any, i) => (
          <Grid item xs={true} key={i}>
            <IconButton size="small">
              <img
                src={require(`../../../../` + x?.Gallery?.ImageURL).default}
                alt={x.Name}
              />
            </IconButton>
            <div className="desc text-overflow label">{x.Name}</div>
          </Grid>
        ))}
      {getUniqueAmenities(Amenities, 'Name')?.length > 0 ? (
        <Grid item xs={true}>
          <IconButton
            size="small"
            onClick={() => dispatch({ type: 'AmenitiesDrawer', payload: {} })}
          >
            <img src={MoreIcon} alt="more" />
          </IconButton>
          <div className="mdDesc">More</div>
        </Grid>
      ) : null}
    </Grid>
  )
  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      console.log('Congrats! Your browser supports Web Share API')
      navigator
        .share({
          url: RedirectUrl,
        })
        .then(() => {
          console.log('Sharing successful')
        })
        .catch(() => {
          console.log('Sharing failed')
        })
    } else {
      console.log('Sorry! Your browser does not support Web Share API')
    }
  }
  return (
    <>
      {loading && <Spinner />}
      <Swiper pagination={true} className="hotel-swiper">
        {HotelPricesByDateRange?.HotelGallery?.map((v: any, i: number) => (
          <SwiperSlide key={i}>
            <img src={v.Gallery?.ImageURL || DefaultImg} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
      <ContentWrapper imgHeader footer>
        <div className="flex-width hotel-detail-wrapper">
          <div className="flex-space">
            <div className="title m-b-4 text-noflow">
              {HotelPricesByDateRange?.HotelName}
            </div>
            <div className="mdDesc text-noflow m-b-4">
              {HotelPricesByDateRange?.HotelAddress?.address},{' '}
              {HotelPricesByDateRange?.HotelAddress?.city},
              {HotelPricesByDateRange?.HotelAddress?.state}
            </div>
            <div className=" m-b-4 m-t-12">
              <div
                className=" desc icon-text rating"
                onClick={() =>
                  history.push(BOOKING_PATH.HOTEL_RATING_REVIEW, {
                    ...localState,
                  })
                }
              >
                <img src={StarIcon} alt={'star'} />{' '}
                {amtStr(HotelPricesByDateRange?.WeightedAverage)}
              </div>
            </div>
          </div>
          <div className="share-info">
            <IconButton
              edge="start"
              size="small"
              // onClick={() => dispatch({ type: 'ShareDrawer', payload: {} })}
              onClick={() => handleShareButton()}
            >
              <img src={ShareIcon} alt="share" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              style={{
                marginRight: 0,
              }}
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${HotelPricesByDateRange?.HotelAddress?.address}+${HotelPricesByDateRange?.HotelAddress?.city}+${HotelPricesByDateRange?.HotelAddress?.state}+${HotelPricesByDateRange?.HotelAddress?.postCode}`
                )
              }
            >
              <img src={LocationIcon} alt="share" />
            </IconButton>
          </div>
        </div>
        <div className="b-b m-t-12 m-b-12" />
        <div className="m-b-4 title">About Hotel</div>
        <div className="flex-width about m-b-12">
          <ShowMoreText
            /* Default options */
            lines={3}
            more="Show more"
            less="Show less"
            className="fs-12 text-noflow "
            anchorClass="show-more"
            // onClick={() => setShowMore(!showMore)}
            expanded={false}
            width={280}
          >
            <span
              className="fs-12 text-noflow"
              dangerouslySetInnerHTML={{
                __html: HotelPricesByDateRange?.AboutHotel,
              }}
            />
          </ShowMoreText>
        </div>
        <div className="title m-b-4">Facilities</div>
        <div className="title m-b-12">{AmenitiesList}</div>
        <div className="m-b-4 title m-b-12">Room Available </div>
        <List className="core-list ">
          {state.RoomAvailable?.map((el, index: number) => (
            <Badge
              key={index}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              color="secondary"
              badgeContent={el.TotalPackages?.reduce((acc, curr) => {
                return acc + curr.TotalNumOfRoom
              }, 0)}
              className="hotel"
            >
              <ListItem
                key={index}
                className="hotel"
                // onClick={() =>
                //   history.push('/booking/hotel/detail', {
                //     HotelDetail: i,
                //     ...localState,
                //   })
                // }
              >
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    alt={el?.Code}
                    src={
                      (el.RoomTypeGallery &&
                        el.RoomTypeGallery[0]?.Gallery?.ImageURL) ||
                      DefaultImg
                    }
                    onClick={() =>
                      history.push(BOOKING_PATH.HOTEL_ROOMTYPE_DETAIL, {
                        ...localState,
                        RoomTypeSelected: el,
                        Hotel: HotelPricesByDateRange,
                      })
                    }
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle fw-500 flex-space">
                        {el.Description}
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="mdDesc  icon icon-text">
                        <img
                          src={AdultsIcon}
                          alt={`${el.MaxGuest}`}
                          width={20}
                          height={20}
                        />{' '}
                        {el.MaxGuest} Adults
                      </span>
                      {el.RoomTypeAmenities?.find(
                        x => x.Name === 'Non-smoking' && x.IsSelected
                      ) && (
                        <span className="mdDesc flex-width icon icon-text">
                          <img
                            src={NoSmokingIcon}
                            alt={`${el.MaxGuest}`}
                            width={20}
                            height={20}
                          />{' '}
                          Non-Smoking
                        </span>
                      )}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={event => {
                      dispatch({
                        type: 'AnchorEl',
                        payload: event.currentTarget,
                      })
                      dispatch({
                        type: 'IndexSelected',
                        payload: index,
                      })
                    }}
                    className="hotel"
                    aria-describedby={ID}
                  >
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Badge>
          ))}
        </List>
      </ContentWrapper>
      {DisplayFooter && (
        <StyledFooter
          sections={{
            isSingle: true,
            option: [
              {
                props: {
                  onClick: () =>
                    history.push(BOOKING_PATH.BOOKING_SUMMARY, {
                      ...localState,
                      RoomTypeSelected: Results,
                      GroupRoomTypeSelected: GroupResults,
                      HotelSelected: HotelPricesByDateRange,
                    }),
                },
                name: 'Book Now',
              },
            ],
          }}
        />
      )}
      {ShareDrawer}
      <PackageOption
        id={ID}
        open={Open}
        state={state}
        dispatch={dispatch}
        handleClose={() => {
          dispatch({ type: 'AnchorEl', payload: null })
          dispatch({ type: 'IndexSelected', payload: null })
        }}
      />
    </>
  )
}
