import React, { lazy } from 'react'
const Login = lazy(() =>
  import('./index').then(module => ({
    default: module.Login,
  }))
)
const Register = lazy(() =>
  import('./index').then(module => ({
    default: module.Register,
  }))
)
const ForgotPassword = lazy(() =>
  import('./index').then(module => ({
    default: module.ForgotPassword,
  }))
)
// const ResetPassword = lazy(() =>
//   import('./index').then(module => ({
//     default: module.ResetPassword,
//   }))
// )
const Logout = lazy(() =>
  import('./index').then(module => ({
    default: module.Logout,
  }))
)
export const PUBLIC_PATH = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/user/reset-password/:token',
  NOT_FOUND_404: '/not-found-404',
  LOGOUT: '/logout',
}
const PublicRoutes = [
  {
    props: { exact: true, path: PUBLIC_PATH.LOGIN },
    component: <Login />,
  },
  {
    props: { exact: true, path: PUBLIC_PATH.REGISTER },
    component: <Register />,
  },
  {
    props: { exact: true, path: PUBLIC_PATH.FORGOT_PASSWORD },
    component: <ForgotPassword />,
  },
  // {
  //   props: { exact: true, path: PUBLIC_PATH.RESET_PASSWORD },
  //   component: <ResetPassword />,
  // },
  {
    props: { exact: true, path: PUBLIC_PATH.LOGOUT },
    component: <Logout />,
  },
]

export default PublicRoutes
