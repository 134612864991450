import {
  ContentWrapper,
  Spinner,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import { Badge, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Close, Mic } from '@material-ui/icons'
import CameraIcon from 'assets/svg/camera.svg'
import RecordIcon from 'assets/svg/record.svg'
import RecordingIcon from 'assets/svg/recording.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import {
  ServiceRequestStatus,
  useDepartmentListingQuery,
  useHousekeepingServiceRequestInsertMutation,
  useHousekeepingServiceRequestUpdateMutation,
} from 'generated/graphql'
import React, { Reducer, useContext, useEffect, useReducer } from 'react'
import { ReactMic } from 'react-mic'
import { useHistory, useLocation } from 'react-router'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'

import useUploadAttachment from '../../../hooks/useUploadAttachment'
import { SERVICE_PATH } from '../Routes'

interface Props {
  Title?: string
  Description?: string
  IsRecording?: boolean
  Audio?: any
  ServiceRequestGallery?: any
  ImageList?: any
}
export const ServiceRequestForm = ({ FormMode }) => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  let {
    files,
    handleUploadChange,
    previewFiles,
    remove,
  } = useUploadAttachment()
  const {
    data: { DepartmentListing } = {
      DepartmentListing: [],
    },
  } = useDepartmentListingQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  console.log(localState, 'form')
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'close',
          title: `${FormMode === 'Add' ? 'New' : 'Edit'} Service Request`,
          props: {
            onClick: () =>
              history.push(SERVICE_PATH.REQ_SERVICE_REQUEST, { ...localState }),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialState: Props = {
    Title: '',
    Description: FormMode === 'Add' ? '' : localState?.Description,
    IsRecording: false,
    Audio: null,
    ServiceRequestGallery:
      FormMode === 'Add' ? [] : localState?.ServiceRequestGallery,
    ImageList: [],
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  //! covert blob to File output

  const AudioDocs = localState?.ServiceRequestGallery?.filter(
    x =>
      x?.Gallery?.BucketFileName.split('.')[
        x?.Gallery?.BucketFileName.split('.')?.length - 1
      ] === 'blob'
  )[0]
  const OtherDocs = localState?.ServiceRequestGallery?.filter(
    x =>
      x?.Gallery?.BucketFileName.split('.')[
        x?.Gallery?.BucketFileName.split('.')?.length - 1
      ] !== 'blob'
  )
  let AttachmentInput =
    state.Audio?.blob && files ? [...files, state.Audio?.blob] : files || null
  const [
    HousekeepingServiceRequestInsert,
    { loading },
  ] = useHousekeepingServiceRequestInsertMutation({
    variables: {
      ServiceRequestInput: {
        AccountID: localState?.Hotel?.AccountID,
        RegistrationID: localState?.Registration?.ID,
        HotelID: localState?.Hotel?.ID,
        // DepartmentID: DepartmentID,

        RoomID: localState?.Room?.ID,
        Title: state.Title,
        Description: state.Description,
        RequestBy: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.Contact
          ?.FullName,
        EstimatedCompletion: new Date(),
        Status: ServiceRequestStatus['Open'],
      },
      // AttachmentInput: [...files, state.Audio?.blob],
      AttachmentInput: AttachmentInput,
    },
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.HousekeepingServiceRequestInsert) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Request Successfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(SERVICE_PATH.REQ_SERVICE_REQUEST, { ...localState })
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Request Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
  })

  const [
    HousekeepingServiceRequestUpdate,
    { loading: UpdateLoading },
  ] = useHousekeepingServiceRequestUpdateMutation({
    variables: {
      ServiceRequestInput: {
        ID: localState?.ID,
        AccountID: localState?.Hotel?.AccountID,
        RegistrationID: localState?.Registration?.ID,
        HotelID: localState?.Hotel?.ID,
        // DepartmentID: DepartmentID,
        RoomID: localState?.Room?.ID,

        Description: state.Description,
        RequestBy: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.Contact
          ?.FullName,
        EstimatedCompletion: new Date(localState?.EstimatedCompletion),
        Status: localState?.Status,
      },
      // AttachmentInput: [...files, state.Audio?.blob],
      AttachmentInput: AttachmentInput,
      OldGalleryID: state.ImageList,
    },
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.HousekeepingServiceRequestUpdate) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Update Request Successfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(SERVICE_PATH.REQ_SERVICE_REQUEST, { ...localState })
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: ' Update Request Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
  })

  const {
    transcript,
    listening,
    resetTranscript,
    // browserSupportsSpeechRecognition ,
  } = useSpeechRecognition()
  // console.log(browserSupportsSpeechRecognition)
  const startListening = () =>
    SpeechRecognition.startListening({
      continuous: true,
      // language: ['zh-CN', 'en-GB', 'ms-MY'] as any,
      language: 'en-GB',
    })

  useEffect(() => {
    if (transcript) {
      dispatch({ type: 'Description', payload: transcript })
    }
  }, [transcript])

  //! reset remarks fields

  const handleResetRemarks = () => {
    resetTranscript()
    dispatch({
      type: 'Description',
      payload: '',
    })
  }
  //! listen and record
  const handleStartListening = () => {
    startListening()
  }

  //!  stop listen and record

  const handleStopListening = () => {
    SpeechRecognition.stopListening()
  }
  const handleStartRecording = () => {
    dispatch({
      type: 'IsRecording',
      payload: true,
    })
  }
  const handleStopRecording = () => {
    dispatch({
      type: 'IsRecording',
      payload: false,
    })
  }
  const onStop = recordedBlob => {
    console.log(recordedBlob, 'HERE')
    dispatch({
      type: 'Audio',
      payload: recordedBlob,
    })
  }
  // console.log(state?.Audio?.blobURL)
  // const blobToFile = (blob, fileName) => {
  //   blob.lastModifiedDate = new Date()
  //   blob.name = fileName
  //   return blob
  // }
  // console.log(URL?.createObjectURL(state.Audio?.blob))
  // console.log(blobToFile(state?.Audio, 'sq'), 'audio')
  console.log(state, 'here')
  return (
    <>
      {(loading || UpdateLoading) && <Spinner />}{' '}
      <ContentWrapper>
        <StyledCardContent>
          {/* <TextField
            label="Title"
            value={state.Title}
            fullWidth
            onChange={e =>
              dispatch({
                type: 'Title',
                payload: e.target.value,
              })
            }
          /> */}
          <TextField
            label="Description"
            value={state.Description}
            multiline
            margin="normal"
            fullWidth
            onChange={e =>
              dispatch({
                type: 'Description',
                payload: e.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {state.Description !== '' && (
                    <IconButton onClick={() => handleResetRemarks()}>
                      <Close />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={
                      !listening ? handleStartListening : handleStopListening
                    }
                    color={!listening ? 'default' : 'secondary'}
                  >
                    <Mic />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div
            className="  m-t-6 m-b-14 remove-badge audio-record"
            // style={{ display: 'none' }}
          >
            {state.Audio?.blobURL && (
              <Badge
                className="remove-badge"
                badgeContent={
                  <Close
                    style={{ width: '12px', height: '12px' }}
                    onClick={() => dispatch({ type: 'Audio', payload: null })}
                  />
                }
                color="secondary"
              >
                <audio controls className="audio-record">
                  <source src={state.Audio?.blobURL} type="audio/mpeg" />
                </audio>
              </Badge>
            )}

            <div
              className={`
                   voice-wave-wrapper
                   ${!state.IsRecording ? 'display-hidden' : ''}`}
            >
              <ReactMic
                record={state.IsRecording}
                visualSetting="sinewave"
                onStop={onStop}
                className={`
                ${!state.IsRecording ? 'display-hidden' : ''}`}
                // onData={onData}
                strokeColor="#2f5cff"
                backgroundColor="#e5e8f7 "
                mimeType="audio/wav"
              />
            </div>
          </div>
          <div className="  m-t-6 m-b-14">
            {previewFiles?.length > 0 &&
              previewFiles?.map((x, i) => (
                <div className="image-preview" key={i}>
                  <Badge
                    badgeContent={
                      <Close
                        style={{ width: '12px', height: '12px' }}
                        onClick={() => {
                          previewFiles.splice(i, 1)
                          remove(x)
                        }}
                      />
                    }
                    color="secondary"
                  >
                    <img src={x} alt="" />
                  </Badge>
                </div>
              ))}
            {state.ServiceRequestGallery?.map((x, i) => {
              return x?.Gallery?.BucketFileName.split('.')[
                x?.Gallery?.BucketFileName.split('.')?.length - 1
              ] === 'blob' ? (
                <Badge
                  className="remove-badge"
                  badgeContent={
                    <Close
                      style={{ width: '12px', height: '12px' }}
                      onClick={() => {
                        dispatch({
                          type: 'ImageList',
                          payload: [...state.ImageList, x?.Gallery?.ID],
                        })

                        state?.ServiceRequestGallery?.splice(i, 1)
                      }}
                    />
                  }
                  color="secondary"
                >
                  <audio
                    src={x.Gallery?.ImageURL}
                    controls
                    className="audio-record"
                  />
                </Badge>
              ) : x?.Gallery?.BucketFileName.split('.')[
                  x?.Gallery?.BucketFileName.split('.')?.length - 1
                ] === 'png' ||
                x?.Gallery?.BucketFileName.split('.')[
                  x?.Gallery?.BucketFileName.split('.')?.length - 1
                ] === 'jpg' ||
                x?.Gallery?.BucketFileName.split('.')[
                  x?.Gallery?.BucketFileName.split('.')?.length - 1
                ] === 'gif' ? (
                <div className="image-preview" key={i}>
                  <Badge
                    badgeContent={
                      <Close
                        style={{ width: '12px', height: '12px' }}
                        onClick={() => {
                          dispatch({
                            type: 'ImageList',
                            payload: [...state.ImageList, x?.Gallery?.ID],
                          })

                          state?.ServiceRequestGallery?.splice(i, 1)
                        }}
                      />
                    }
                    color="secondary"
                  >
                    <img src={x.Gallery?.ImageURL} alt="" />
                  </Badge>
                </div>
              ) : null
            })}
          </div>
          <div>
            <IconButton
              size="medium"
              className={
                !state.IsRecording ? 'common-icon' : 'common-secondary-icon'
              }
              style={{ marginRight: '20px' }}
              // onTouchStart={handleStartListening}
              // onMouseDown={handleStartListening}
              // onTouchEnd={handleStopListening}
              // onMouseUp={handleStopListening}
              onClick={
                !state.IsRecording ? handleStartRecording : handleStopRecording
              }
              // color={!state.IsRecording ? 'default' : 'secondary'}
            >
              <img
                src={!state.IsRecording ? RecordIcon : RecordingIcon}
                alt="record"
              />
            </IconButton>
            <label htmlFor="contained-button-file">
              <IconButton
                size="medium"
                className="common-icon"
                component="span"
              >
                <img src={CameraIcon} alt="media" />
              </IconButton>
            </label>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              hidden
              onChange={e => handleUploadChange(e)}
            />
          </div>
        </StyledCardContent>
      </ContentWrapper>
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () =>
                  FormMode === 'Add'
                    ? HousekeepingServiceRequestInsert()
                    : HousekeepingServiceRequestUpdate(),
                disabled: !state.Description,
              },
              name: 'Request',
            },
          ],
        }}
      />
    </>
  )
}
