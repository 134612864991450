import { IconButton, Popover } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import { amtStr } from 'containers/Utils/numFormatter'
import React from 'react'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

export const PackageOption = props => {
  const { id, open, state, dispatch, handleClose } = props

  const handleMinus = (i: number) => {
    const newArr: any = state.RoomAvailable[state?.IndexSelected]?.TotalPackages
    let selected = newArr[i]
    selected.TotalNumOfRoom =
      state.RoomAvailable[state?.IndexSelected]?.TotalPackages[i]
        .TotalNumOfRoom - 1
    const results = state.RoomAvailable.map((el: any, index: number) =>
      state.IndexSelected === index
        ? {
            ...el,
            TotalPackages: newArr,
          }
        : el
    )
    dispatch({ type: 'RoomAvailable', payload: results })
  }
  const handlePlus = (i: number) => {
    const newArr: any = state.RoomAvailable[state?.IndexSelected]?.TotalPackages
    let selected = newArr[i]
    selected.TotalNumOfRoom =
      state.RoomAvailable[state?.IndexSelected]?.TotalPackages[i]
        .TotalNumOfRoom + 1
    const results = state.RoomAvailable.map((el, index) =>
      state.IndexSelected === index
        ? {
            ...el,
            TotalPackages: newArr,
          }
        : el
    )
    dispatch({ type: 'RoomAvailable', payload: results })
  }
  return (
    <Popover
      className="filter-popover"
      id={id}
      open={open}
      anchorEl={state.AnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          padding: '12px',
        },
      }}
    >
      <div className="flex-width desc m-b-4">Select Rate</div>
      {state.RoomAvailable[state?.IndexSelected]?.TotalPackages?.map((x, i) => (
        <div className="flex-width m-b-12" key={i}>
          <div className="xsTitle flex-space" style={{ margin: 'auto' }}>
            <div>{x.PackageName}</div>
            <div className="color-blue icon-text">
              {' '}
              <MonetizationOnIcon /> {amtStr(x.Price)}
            </div>
          </div>

          <div className="desc plus-minus-section ">
            <IconButton
              aria-label="delete"
              size="small"
              disabled={x.TotalNumOfRoom === 0}
              onClick={() => handleMinus(i)}
            >
              <Remove />
            </IconButton>

            <span className="xsTitle counts">{x.TotalNumOfRoom}</span>
            <IconButton
              aria-label="delete"
              size="small"
              disabled={
                Number(
                  state.RoomAvailable[
                    state.IndexSelected
                  ]?.TotalPackages?.reduce((acc, curr) => {
                    return acc + curr.TotalNumOfRoom
                  }, 0)
                ) >=
                  state.RoomAvailable[
                    state.IndexSelected
                  ]?.RoomTypeAvailability?.sort((a, b) =>
                    a.TotalVacant > b.TotalVacant ? 1 : -1
                  )[0]?.TotalVacant || x.Price === 0
              }
              onClick={() => handlePlus(i)}
            >
              <Add />
            </IconButton>
          </div>
        </div>
      ))}
    </Popover>
  )
}
