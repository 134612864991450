import {
  ContentWrapper,
  MainHeader,
  Spinner,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@material-ui/core'
import DefaultImg from 'assets/images/default-img.png'
import HotelBg from 'assets/images/hotel-detail.jpg'
import CheckInBedIcon from 'assets/svg/checkin-bed.svg'
import EditIcon from 'assets/svg/edit.svg'
import MoreIcon from 'assets/svg/more.svg'
import PersonIcon from 'assets/svg/person.svg'
import RemoveIcon from 'assets/svg/remove.svg'
import DurationIcon from 'assets/svg/stay-duration.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { StateEnum } from 'containers/Utils/Enum'
import { amtStr } from 'containers/Utils/numFormatter'
import { OCRhelper } from 'containers/Utils/OCRhelper'
import { isToday, isWithinInterval } from 'date-fns'
import dateFormat from 'dateformat'
import {
  BookingStatus,
  Nationality,
  useBookingDetailsQuery,
  useCancelBookingMutation,
  useFolioListingByRegistrationLazyQuery,
  useGuestContactUpdateMutation,
  useGuestInsertMutation,
  useGuestProfileDeleteMutation,
  useLoggedInGuestProfileQuery,
} from 'generated/graphql'
import moment from 'moment'
import React, {
  FC,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import { useHistory, useLocation } from 'react-router'
import { BookingSummaryDialog } from '../Hotel/BookingSummaryDialog'
import { BOOKING_PATH } from '../Routes'
import { AddPopOver } from './AddPopOver'
import SkeletonCard from 'components/Skeleton/SkeletonCard'
import InvoiceIcon from 'assets/svg/invoice.svg'
import Webcam from 'react-webcam'
import { ReactComponent as ScanBorderIcon } from 'assets/images/scan-border.svg'
import CloseIcon from '@material-ui/icons/Close'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
interface Props {
  RoomingList?: any
  GuestInfo?: {
    ID?: string | null
    GuestID?: string | null
    FullName?: string
    Nationality?: string
    MobileNo?: string
    Email?: string
  }
  Dialog?: {
    Open?: boolean
    Mode?: DialogMode
  }
  AnchorEl: null | undefined | HTMLElement
  IndexSelected: any
  GuestSelected: any
  Scanning?: boolean
  Blob?: null | any
  GuestSelectedInfo?: any
  IsInvoice?: boolean
}
export const BookingDetail: FC = () => {
  const history = useHistory()
  const {
    data: { loggedInGuestProfile } = { loggedInGuestProfile: null },
  } = useLoggedInGuestProfileQuery({
    fetchPolicy: 'network-only',
  })
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const location = useLocation()
  const localState = location.state as any
  const [
    FolioQuery,
    {
      data: { FolioListingByRegistration } = { FolioListingByRegistration: [] },
    },
  ] = useFolioListingByRegistrationLazyQuery({
    // variables: {
    //   HotelID: localState?.Hotel?.ID,
    //   BookingID: localState?.Registration?.Booking?.ID,
    //   RegistrationID: localState?.Registration?.ID,
    //   AccountID: localState?.Hotel?.AccountID,
    // },
    fetchPolicy: 'no-cache',
  })
  const {
    refetch,
    loading,
    data: { BookingDetails } = { BookingDetails: null },
  } = useBookingDetailsQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
      ID: localState?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  const BeforeArrivalDate = dateFormat(
    new Date(BookingDetails?.ArrivalDate).setDate(
      new Date(BookingDetails?.ArrivalDate).getDate() -
        BookingDetails?.Hotel?.AllowCancellationDays || 0
    ),
    'yyyy-mm-dd'
  )
  const IsAllowCancel = isWithinInterval(
    new Date(moment(new Date()).utc().format('MM/DD/YYYY')),
    {
      start: new Date(moment(BeforeArrivalDate).utc().format('MM/DD/YYYY')),
      end: new Date(
        moment(BookingDetails?.ArrivalDate).utc().format('MM/DD/YYYY')
      ),
    }
  )
  const initialState: Props = {
    RoomingList: [],
    GuestInfo: {
      ID: '',
      GuestID: '',
      FullName: '',
      Nationality: '',
      MobileNo: '',
      Email: '',
    },
    Dialog: {
      Open: false,
      Mode: '',
    },
    AnchorEl: null,
    IndexSelected: 0,
    GuestSelected: null,
    Scanning: false,
    Blob: null,
    GuestSelectedInfo: null,
    IsInvoice: false,
  }

  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'AddDialog':
        return { ...state, Dialog: { Open: true, Mode: 'Add' }, AnchorEl: null }
      case 'DeleteDialog':
        return { ...state, Dialog: { Open: true, Mode: 'Delete' } }
      case 'CancelDialog':
        return { ...state, Dialog: { Open: true, Mode: 'Cancel' } }
      case 'UpdateDialog':
        return {
          ...state,
          Dialog: { Open: true, Mode: 'Edit' },
          AnchorEl: null,
        }
      case 'CloseDialog':
        return {
          ...state,
          Dialog: { Open: false, Mode: '' },
          GuestInfo: {
            ID: '',
            GuestID: '',
            FullName: '',
            Nationality: '',
            MobileNo: '',
            Email: '',
          },
        }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title:
            BookingDetails?.BookingStatus === 'Booked' &&
            isToday(new Date(BookingDetails?.ArrivalDate))
              ? 'Check In'
              : 'Booking Details',
          props: {
            onClick: () => history.push(BOOKING_PATH.BOOKING_LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BookingDetails])

  useEffect(() => {
    let RoomingListTemp: any[] = []
    if (BookingDetails) {
      BookingDetails?.BookedRegistration?.map(i => {
        if (i.IsPrincipal) {
          RoomingListTemp.push({
            ...i,
            Guests: BookingDetails?.BookedRegistration?.filter(
              x => x.SharerNo === i.SharerNo
            ),
          })
        }
      })
      dispatch({
        type: 'RoomingList',
        payload: RoomingListTemp,
      })
    }
  }, [BookingDetails])
  const PaidPrice = BookingDetails => {
    let Price = 0
    BookingDetails?.BookedRegistration?.map(i => {
      i?.IsPrincipal && (Price += i?.TotalRoomPrice || 0)
    })
    return amtStr(Price)
  }

  const Open = Boolean(state.AnchorEl)
  const ID = Open ? 'simple-popover' : undefined
  const handleAddGuestPopOver = (event, i: number) => {
    dispatch({
      type: 'AnchorEl',
      payload: event.currentTarget,
    })
    dispatch({
      type: 'IndexSelected',
      payload: i,
    })
  }
  const [GuestInsert, { loading: InsertLoading }] = useGuestInsertMutation({
    variables: {
      ContactInput: [
        {
          FullName: state.GuestInfo?.FullName,
          AccountID: BookingDetails?.Hotel?.AccountID,
          Nationality: Nationality[state.GuestInfo?.Nationality],
          MobileNo: state.GuestInfo?.MobileNo,
          Email: state.GuestInfo?.Email,
        },
      ],
      AttachmentInput: null,
      BookingID: BookingDetails?.ID,
      RegistrationID: state.RoomingList[state?.IndexSelected]?.ID,
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        return RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
          },
        })
      })
      dispatch({
        type: 'CloseDialog',
        payload: {},
      })
    },
    onCompleted: data => {
      if (data.GuestInsert) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Add Guest Sucessfully',
          },
        })
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
      }

      refetch()
    },
  })
  const handleUpdateGuestDialog = (i: number, i2: number, el: any) => {
    dispatch({
      type: 'IndexSelected',
      payload: i,
    })
    dispatch({
      type: 'GuestSelected',
      payload: i2,
    })
    dispatch({
      type: 'GuestInfo',
      payload: {
        ID: el?.Guest?.Contact?.ID,
        GuestID: el?.Guest?.ID,
        FullName: el?.Guest?.Contact?.FullName,
        Nationality: el?.Guest?.Contact?.Nationality,
        MobileNo: el?.Guest?.Contact?.MobileNo,
        Email: el?.Guest?.Contact?.Email,
      },
    })
    dispatch({
      type: 'UpdateDialog',
      payload: {},
    })
  }
  const [
    GuestContactUpdate,
    { loading: UpdateLoading },
  ] = useGuestContactUpdateMutation({
    variables: {
      BookingID: BookingDetails?.ID,
      HotelID: localState?.Hotel?.ID,
      RegistrationID: state.RoomingList[state?.IndexSelected]?.ID,
      IsPrincipal: state.GuestInfo?.GuestID === loggedInGuestProfile?.ID,
      ContactInput: {
        ID: state.GuestInfo?.ID,
        FullName: state.GuestInfo?.FullName,
        AccountID: BookingDetails?.Hotel?.AccountID,
        Nationality: Nationality[state.GuestInfo?.Nationality],
        MobileNo: state.GuestInfo?.MobileNo,
        Email: state.GuestInfo?.Email,
      },
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        return RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
          },
        })
      })
      dispatch({
        type: 'CloseDialog',
        payload: {},
      })
    },
    onCompleted: data => {
      if (data.GuestContactUpdate) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Update Guest Sucessfully',
          },
        })
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
      }
      refetch()
    },
  })
  const [CancelBooking, { loading: CancelLoading }] = useCancelBookingMutation({
    variables: {
      BookingID: BookingDetails?.ID,
      HotelID: localState?.Hotel?.ID,
    },
    onCompleted: data => {
      if (data.CancelBooking) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Cancel booking Sucessfully',
          },
        })
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
      }
      refetch()
    },
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        return RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
          },
        })
      })
      dispatch({
        type: 'CloseDialog',
        payload: {},
      })
    },
  })
  const handleScanID = async e => {
    dispatch({ type: 'Scanning', payload: true })
    if (e.target.files[0]) {
      let OCRResult: any = await OCRhelper(e.target.files[0])
      const Results = {
        Blob: OCRResult?.Blob,
        FullName:
          OCRResult?.passportholdername && OCRResult?.passportnumber
            ? OCRResult?.passportholdername
            : OCRResult?.mykadname || '',
        Nationality: 'Malaysian' || '',
        NRIC:
          OCRResult?.passportholdername && OCRResult?.passportnumber
            ? OCRResult?.passportnumber
            : OCRResult?.mykadno || '',
        Address: {
          country: OCRResult?.isMyKad
            ? 'Malaysia'
            : OCRResult?.passportcountry || '',
          address: OCRResult?.mykadaddress || '',
          city: OCRResult?.mykadcity || '',
          postCode: OCRResult?.mykadpostcode || '',
          state:
            StateEnum[
              OCRResult?.mykadstate?.charAt(0)?.toUpperCase() +
                OCRResult?.mykadstate?.slice(1)?.toLowerCase()
            ] || '',
        },
      }
      dispatch({
        type: 'ScanInfo',
        payload: Results,
      })
      history.push(BOOKING_PATH.BOOKING_CHECKIN, {
        Data: state.GuestSelectedInfo,
        ScanInfo: Results,
      })
    }
    dispatch({
      type: 'Scanning',
      payload: false,
    })

    setOpen(false)
  }
  const [
    GuestProfileDelete,
    { loading: DeleteLoading },
  ] = useGuestProfileDeleteMutation({
    variables: {
      BookingID: BookingDetails?.ID,
      GuestID:
        state.RoomingList[state?.IndexSelected]?.Guests[state.GuestSelected]
          ?.Guest?.ID,
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        return RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
          },
        })
      })
      dispatch({
        type: 'CloseDialog',
        payload: {},
      })
    },
    onCompleted: data => {
      if (data.GuestProfileDelete) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Delete Guest Sucessfully',
          },
        })
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
      }
      refetch()
    },
  })

  const videoConstraints = {
    // facingMode: { exact: 'environment' },
    facingMode: 'environment',
  }

  const webcamRef = React.useRef(null)
  const inputFile = React.useRef(null)
  console.log(state?.GuestSelectedInfo)
  const captureIC = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()
    fetch(imageSrc)
      .then(res => {
        return res.arrayBuffer()
      })
      .then(async buf => {
        dispatch({ type: 'Scanning', payload: true })

        let OCRResult: any = await OCRhelper(
          new File([buf], 'FullName', { type: 'image/png' })
        )
        const Results = {
          Blob: OCRResult?.Blob,
          FullName:
            OCRResult?.passportholdername && OCRResult?.passportnumber
              ? OCRResult?.passportholdername
              : OCRResult?.mykadname || '',
          Nationality: 'Malaysian' || '',
          NRIC:
            OCRResult?.passportholdername && OCRResult?.passportnumber
              ? OCRResult?.passportnumber
              : OCRResult?.mykadno || '',
          Address: {
            country: OCRResult?.isMyKad
              ? 'Malaysia'
              : OCRResult?.passportcountry || '',
            address: OCRResult?.mykadaddress || '',
            city: OCRResult?.mykadcity || '',
            postCode: OCRResult?.mykadpostcode || '',
            state:
              StateEnum[
                OCRResult?.mykadstate?.charAt(0)?.toUpperCase() +
                  OCRResult?.mykadstate?.slice(1)?.toLowerCase()
              ] || '',
          },
        }
        dispatch({
          type: 'ScanInfo',
          payload: Results,
        })
        dispatch({
          type: 'Scanning',
          payload: false,
        })
        setOpen(false)
        if (Results) {
          history.push(BOOKING_PATH.BOOKING_CHECKIN, {
            Data: state?.GuestSelectedInfo,

            ScanInfo: Results,
          })
        }
      })
      .catch(err => {
        console.error(err)
        dispatch({
          type: 'Scanning',
          payload: false,
        })
      })
  }, [webcamRef, state?.GuestSelectedInfo])

  const [open, setOpen] = React.useState(false)

  return (
    <>
      {(UpdateLoading ||
        DeleteLoading ||
        InsertLoading ||
        CancelLoading ||
        state.Scanning) && <Spinner />}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          className="video-section"
          component="section"
          height="100vh"
          display="flex"
          alignItems="center"
          width={'100%'}
          maxWidth={{ sm: '1200px' }}
          margin={{ sm: '0 auto' }}
        >
          <MainHeader
            sections={{
              left: {
                icon: 'leftArrow',
                title: 'Scan ID',
                props: {
                  onClick: () => setOpen(false),
                },
              },
            }}
          />

          <Box
            component="section"
            overflow="hidden"
            position="relative"
            width="100%"
            paddingTop="100%"
            // height="150px"
            display="block"
          >
            <Box
              top="0px"
              left="0px"
              zIndex={100}
              boxSizing="border-box"
              position="absolute"
              width="100%"
              height="100%"
            >
              <Box
                justifyContent="center"
                margin="0 auto"
                display="flex"
                alignItems="center"
                height="100%"
                className="scan-border"
              >
                <ScanBorderIcon />
              </Box>
            </Box>

            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </Box>
          <Box
            width="100%"
            zIndex={100}
            position="fixed"
            bottom="32px"
            right="0"
            bgcolor="#00000080"
            padding="50px 0px"
          >
            <Box
              display="flex"
              width="100%"
              margin="0 auto"
              justifyContent="space-evenly"
            >
              <IconButton
                aria-label="delete"
                onClick={() => {
                  captureIC()
                }}
              >
                <RadioButtonCheckedIcon
                  style={{ color: '#fff', fontSize: '30px' }}
                />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => inputFile.current.click()}
              >
                <InsertPhotoIcon style={{ color: '#fff', fontSize: '30px' }} />
              </IconButton>
            </Box>
          </Box>

          {/* <StyledFooter
            sections={{
              option: [
                {
                  props: {
                    onClick: () => captureIC(),
                  },
                  name: 'Capture',
                },
                {
                  props: {
                    onClick: () => inputFile.current.click(),
                  },
                  name: 'Choose from gallery',
                },
              ],
            }}
          /> */}
        </Box>
      </Modal>
      <ContentWrapper footer>
        {loading ? (
          <SkeletonCard type="BackgroundImg" />
        ) : (
          <StyledCardContent>
            <div className="rm-padding-card ">
              <img src={HotelBg} alt="bg" />
              <div className="logo-section">
                <img
                  src={BookingDetails?.Hotel?.LogoImage?.ImageURL || DefaultImg}
                  alt="bg"
                />
              </div>
            </div>
          </StyledCardContent>
        )}
        {loading ? (
          <SkeletonCard type="BookingInfo" />
        ) : (
          <StyledCardContent>
            <Grid
              container
              justify="flex-start"
              className="m-b-16"
              style={{ textAlign: 'center' }}
              // onClick={() => setOpen(true)}
            >
              <Grid item xs={4}>
                <div className="desc color-text-2 m-b-4">Check In</div>
                <div className="title ">
                  {dateFormat(BookingDetails?.ArrivalDate, 'dd.mm.yyyy')}
                </div>
              </Grid>
              <Grid item xs={4} className="auto-margin">
                <div className="auto-margin stay-duration">
                  <img
                    src={DurationIcon}
                    alt="duration"
                    width={15}
                    height={15}
                    className="m-b-4"
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="desc color-text-2 m-b-4">Check Out</div>
                <div className="title ">
                  {dateFormat(BookingDetails?.DepartureDate, 'dd.mm.yyyy')}
                </div>
              </Grid>
            </Grid>
            <div className="flex-width color-text-1 m-b-8 mdDesc">
              <span className=" flex-space">No of Room(s)</span>
              <span className="">{state.RoomingList?.length}</span>
            </div>
            <div className="flex-width color-text-1 m-b-8 mdDesc">
              <span className=" flex-space">Booked on</span>
              <span className="">
                {dateFormat(BookingDetails?.CreatedDT, 'ddd, dd mmm yyyy')}
              </span>
            </div>
            {BookingDetails?.BookingStatus !== 'Pending' &&
              BookingDetails?.AdvancePayment && (
                <div className="flex-width color-text-1 m-b-8 mdDesc">
                  <span className=" flex-space">Payment Method</span>
                  <span className="">
                    {' '}
                    {BookingDetails?.AdvancePayment &&
                      BookingDetails?.AdvancePayment[0]?.PaymentType}
                  </span>
                </div>
              )}
            {BookingDetails?.BookingStatus !== 'Pending' && (
              <div className="flex-width color-text-1 m-b-8 mdDesc">
                <span className=" flex-space">Paid</span>
                <span className="">$ {PaidPrice(BookingDetails)} </span>
              </div>
            )}
          </StyledCardContent>
        )}
        {state.RoomingList?.map((x, index) => (
          <StyledCardContent key={index}>
            <div className="flex-width room-detail-section m-b-16">
              <div className="flex-space auto-margin">
                <div className="title m-b-4 ">
                  Room {index + 1}
                  {BookingDetails?.BookingStatus !== BookingStatus.CheckOut ? (
                    <IconButton
                      size="small"
                      onClick={e => handleAddGuestPopOver(e, index)}
                    >
                      <img src={MoreIcon} alt="more" />
                    </IconButton>
                  ) : null}
                </div>
                <div className="desc color-text-2 text-overflow">
                  <span className="">{x?.Room?.RoomNo || '-'}</span>
                  <span className="text-separator">|</span>
                  <span className="">{x.RoomType?.Description}</span>
                  {/* <a href={`tel:${123123}`}>123123123</a>
                <span className="text-separator">|</span>{' '}
                <a href={`mailto:`}> chia</a> */}
                </div>
              </div>{' '}
              {BookingDetails?.BookingStatus !== BookingStatus.CheckOut ? (
                <div className="check-in-bed">
                  <img src={CheckInBedIcon} alt="bed" />
                </div>
              ) : (
                <IconButton
                  size="medium"
                  className="edit-icon"
                  style={{ height: '25px' }}
                  onClick={e => {
                    dispatch({ type: 'IsInvoice', payload: true })
                    FolioQuery({
                      variables: {
                        HotelID: BookingDetails?.Hotel?.ID,
                        BookingID: BookingDetails?.ID,
                        RegistrationID: x?.ID,
                        AccountID: BookingDetails?.Hotel?.AccountID,
                      },
                    })
                    handleAddGuestPopOver(e, index)
                  }}
                >
                  <img src={InvoiceIcon} alt="review" />
                </IconButton>
              )}
            </div>
            {x.Guests?.map((el, guestIndex) => (
              <Grid
                key={guestIndex}
                justify="flex-start"
                container
                className="guest-section m-b-20"
              >
                <Grid item xs={9}>
                  <div className="mdDesc color-text-1 icon-text m-b-4">
                    <img src={PersonIcon} alt="person" />
                    {el?.Guest?.Contact?.FullName}
                  </div>
                  <div className="desc color-text-1 text-overflow">
                    <a href={`tel:${el?.Guest?.Contact?.MobileNo}`}>
                      {' '}
                      {el?.Guest?.Contact.MobileNo || '-'}
                    </a>
                    <span className="text-separator">|</span>{' '}
                    <a href={`mailto:${el?.Guest?.Contact?.Email}`}>
                      {' '}
                      {el?.Guest?.Contact.Email || '-'}
                    </a>
                  </div>
                </Grid>
                <Grid item xs={3} className="guest-details">
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    onChange={e => handleScanID(e)}
                    hidden
                    type="file"
                    ref={inputFile}
                  />
                  {BookingDetails?.BookingStatus !== BookingStatus.CheckOut ? (
                    <>
                      {isToday(new Date(BookingDetails?.ArrivalDate)) &&
                      el?.RoomID &&
                      el.RegistrationStatus === 'Booked' ? (
                        // <label
                        //   htmlFor={
                        //     el.Room?.RoomStatus?.CleanOrDirty !== 'Dirty' &&
                        //     'contained-button-file'
                        //   }
                        // >
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          className="checkin-btn"
                          component="span"
                          onClick={() => {
                            if (el.Room?.RoomStatus?.CleanOrDirty === 'Dirty') {
                              RootDispatch({
                                type: 'OnSnackBar',
                                payload: {
                                  ...RootState.OnSnackBar,
                                  Open: true,
                                  Message:
                                    'Room not ready! Please contact Front Office.',
                                },
                              })
                            } else {
                              dispatch({
                                type: 'GuestSelectedInfo',
                                payload: {
                                  ...localState,
                                  BookingDetails: BookingDetails,
                                  Registration: x,
                                  Guest: el,
                                },
                              })
                              setOpen(true)
                            }
                          }}

                          // onClick={e => handleScanID(e)}
                          // onClick={() =>
                          //   history.push(BOOKING_PATH.BOOKING_CHECKIN, {
                          //     ...localState,
                          //     BookingDetails: BookingDetails,
                          //     Registration: x,
                          //     Guest: el,
                          //   })
                          // }
                        >
                          Check in
                        </Button>
                      ) : !el?.RoomID &&
                        el.RegistrationStatus === 'Booked' ? null : (
                        <>
                          <IconButton
                            size="small"
                            className="edit-icon"
                            onClick={() =>
                              handleUpdateGuestDialog(index, guestIndex, el)
                            }
                          >
                            <img src={EditIcon} alt="Delete" />
                          </IconButton>

                          <IconButton
                            size="small"
                            disabled={
                              JSON.parse(localStorage.getItem('GuestLoginInfo'))
                                ?.ID === el.Guest?.ID
                            }
                            onClick={() => {
                              if (el.IsPrincipal) {
                                RootDispatch({
                                  type: 'OnSnackBar',
                                  payload: {
                                    ...RootState.OnSnackBar,
                                    Open: true,
                                    Message: 'Cannot delete principal guest!',
                                  },
                                })
                              } else {
                                dispatch({ type: 'DeleteDialog', payload: {} })
                                dispatch({
                                  type: 'IndexSelected',
                                  payload: index,
                                })
                                dispatch({
                                  type: 'GuestSelected',
                                  payload: guestIndex,
                                })
                              }
                            }}
                          >
                            <img
                              src={RemoveIcon}
                              alt="Delete"
                              style={{
                                marginRight: 0,
                              }}
                            />
                          </IconButton>
                        </>
                      )}
                    </>
                  ) : null}
                </Grid>
              </Grid>
            ))}
          </StyledCardContent>
        ))}
      </ContentWrapper>
      {BookingDetails?.BookingStatus === BookingStatus.Booked ? (
        <StyledFooter
          sections={{
            isSingle: true,
            option: [
              {
                props: {
                  onClick: () => {
                    if (IsAllowCancel) {
                      RootDispatch({
                        type: 'OnSnackBar',
                        payload: {
                          ...RootState.OnSnackBar,
                          Open: true,
                          Message: 'Cannot cancel the booking.',
                        },
                      })
                    } else {
                      dispatch({
                        type: 'CancelDialog',
                        payload: {},
                      })
                    }
                  },
                  disabled:
                    IsAllowCancel ||
                    BookingDetails?.BookedRegistration?.find(
                      x => x.RegistrationStatus === 'Inhouse'
                    )
                      ? true
                      : false,
                },
                name: 'Cancel Booking',
              },
            ],
          }}
        />
      ) : null}
      <AddPopOver
        ID={ID}
        Open={Open}
        state={state}
        dispatch={dispatch}
        FolioListingByRegistration={FolioListingByRegistration}
        localState={localState}
      />
      <BookingSummaryDialog
        Mode={'Detail'}
        state={state}
        dispatch={dispatch}
        LoggedGuest={loggedInGuestProfile}
        TotalDiscountAmount={null}
        OnlineBookingInsert={null}
        handleGuestInsert={GuestInsert}
        handleGuestContactUpdate={GuestContactUpdate}
        handleGuestProfileDelete={GuestProfileDelete}
        handleCancelBooking={CancelBooking}
      />
    </>
  )
}
