import {
  ContentWrapper,
  LeftArrow,
  StyledCardContent,
} from '@ifca-ui/neumorphism'
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import HotelIcon from 'assets/svg/account/about-hotel.svg'
import CookiePolicyIcon from 'assets/svg/account/cookie-policy.svg'
import PrivacyPolicyIcon from 'assets/svg/account/privacy-policy.svg'
import TermInUseIcon from 'assets/svg/account/term-in-use.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import React, { FC, useContext, useEffect, useReducer, Reducer } from 'react'
import { useHistory } from 'react-router'
import { OCRhelper, OCRProcessing } from 'containers/Utils/OCRhelper'
import { useBlinkID } from 'containers/hooks/useBlinkID'
import CloseIcon from '@material-ui/icons/Close'
import axios from 'axios'
import { useHuaweiOcrMutation, useInnov8OcrMutation } from 'generated/graphql'
import { ScanIDModal } from 'components/ScanID/ScanIDModal'
import { ScanIDModal as GoogleScanIDModal } from 'components/ScanID/ScanIDModal'
import { ScanIDModal as InnoScanIDModal } from 'components/ScanID/ScanIDModal'

interface Props {
  Open?: boolean
  Results?: {
    FullName?: string
    NRIC?: string
    Address?: string
    City?: string
    State?: string
    Country?: string
    PostCode?: string
  }
  Randoms?: any
  HuaweiOpen?: boolean
  GoogleOpen?: boolean
  InnoOpen?: boolean
}
export const OCRTest: FC = () => {
  const history = useHistory()

  const { RootDispatch } = useContext<AppContextProps>(AppContext)

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'OCR Test',
          props: {
            onClick: () => history.goBack(),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialState: Props = {
    Open: false,
    Results: {
      FullName: '',
      NRIC: '',
      Address: '',
      City: '',
      State: '',
      Country: '',
      PostCode: '',
    },
    Randoms: null,
    HuaweiOpen: false,
    GoogleOpen: false,
    InnoOpen: false,
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const AboutUsList = [
    {
      Icon: PrivacyPolicyIcon,
      Title: 'Google vision',
      onClick: () => {
        dispatch({ type: 'GoogleOpen', payload: true })
        dispatch({
          type: 'Results',
          payload: {},
        })
      },
    },
    {
      Icon: CookiePolicyIcon,
      Title: 'BlinkID',
      onClick: () => {
        handleBlinkID()
        dispatch({ type: 'Open', payload: true })
        dispatch({
          type: 'Results',
          payload: {},
        })
      },
    },
    {
      Icon: TermInUseIcon,
      Title: 'Huawei OCR',
      // onClick: () => huaweiInputFile.current.click(),
      onClick: () => {
        dispatch({ type: 'HuaweiOpen', payload: true })

        dispatch({
          type: 'Results',
          payload: {},
        })
      },
    },
    {
      Icon: TermInUseIcon,
      Title: 'Inno8tif',
      // onClick: () => huaweiInputFile.current.click(),
      onClick: () => {
        dispatch({ type: 'InnoOpen', payload: true })
        dispatch({
          type: 'Results',
          payload: {},
        })
      },
    },
  ]

  const [HuaweiOcr] = useHuaweiOcrMutation({})
  const [Innov8OCR] = useInnov8OcrMutation({})
  const handleHuaweiInputFile = async e => {
    console.log(e.target.files[0])
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    const image: any = await toBase64(e.target.files[0])
    HuaweiOcr({
      variables: {
        File: image?.split('base64,')[1],
        // URL: 'https://iam.ap-southeast-2.myhuaweicloud.com/v3/auth/tokens',
      },
    }).then(async i => {
      if (i.data.HuaweiOCR) {
        const OCRResult = await OCRProcessing(
          'Huawei',
          i.data.HuaweiOCR?.result?.words_block_list
            ?.map(x => x.words)
            .join('\n')
        )

        dispatch({
          type: 'Results',
          payload: {
            FullName: OCRResult.mykadname,
            NRIC: OCRResult.mykadno,
            Address: OCRResult.mykadaddress,
            City: OCRResult.mykadcity,
            State: OCRResult.mykadstate,
            Country: 'MALAYSIA',
            PostCode: OCRResult.mykadpostcode,
          },
        })
        dispatch({
          type: 'HuaweiOpen',
          payload: false,
        })
      }
    })
  }

  console.log(state.Results.Address?.split('^'), 'here')
  const inputFile = React.useRef(null)
  const huaweiInputFile = React.useRef(null)
  const innoInputFile = React.useRef(null)

  const handleInnoUpload = async e => {
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    const image: any = await toBase64(e.target.files[0])

    Innov8OCR({
      variables: {
        File: image?.split('base64,')[1],
      },
    }).then(i => {
      if (i.data.Innov8OCR) {
        const OCRResult =
          i.data.Innov8OCR?.result[0]?.ListVerifiedFields?.pFieldMaps
        const addressResults = OCRResult?.find(
          x => x.wFieldType === 17
        )?.Field_Visual?.split('^')
        const postCodeCityRow = addressResults?.slice(-2)[0]
        const State =
          addressResults?.slice(-1)[0]?.toLowerCase().charAt(0).toUpperCase() +
          addressResults?.slice(-1)[0]?.toLowerCase()?.slice(1)
        const City =
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            .charAt(0)
            .toUpperCase() +
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            ?.slice(1)
        const Address = addressResults?.slice(0, -2).join(', ')
        const PostCode = postCodeCityRow?.substr(
          0,
          postCodeCityRow?.indexOf(' ')
        )
        dispatch({
          type: 'Results',
          payload: {
            FullName: OCRResult?.find(x => x.wFieldType === 25)?.Field_Visual,
            NRIC: OCRResult?.find(x => x.wFieldType === 2)?.Field_Visual,
            Address: Address,
            City: City,
            State: State,
            Country: OCRResult?.find(x => x.wFieldType === 38)?.Field_Visual,
            PostCode: PostCode,
          },
        })
        dispatch({
          type: 'InnoOpen',
          payload: false,
        })
      }
      dispatch({
        type: 'InnoOpen',
        payload: false,
      })
    })
  }
  const innoWebCamRef = React.useRef(null)

  const captureInnoIC = React.useCallback(async () => {
    const image = innoWebCamRef.current.getScreenshot()
    console.log(image, 'image')
    Innov8OCR({
      variables: {
        File: image?.split('base64,')[1],
      },
    }).then(i => {
      if (i.data.Innov8OCR.status !== 'error') {
        const OCRResult =
          i.data.Innov8OCR?.result[0]?.ListVerifiedFields?.pFieldMaps
        const addressResults = OCRResult?.find(
          x => x.wFieldType === 17
        )?.Field_Visual?.split('^')
        const postCodeCityRow = addressResults?.slice(-2)[0]
        const State =
          addressResults?.slice(-1)[0]?.toLowerCase().charAt(0).toUpperCase() +
          addressResults?.slice(-1)[0]?.toLowerCase()?.slice(1)
        const City =
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            .charAt(0)
            .toUpperCase() +
          postCodeCityRow
            ?.substr(postCodeCityRow?.indexOf(' ') + 1)
            ?.toLowerCase()
            ?.slice(1)
        const Address = addressResults?.slice(0, -2).join(', ')
        const PostCode = postCodeCityRow?.substr(
          0,
          postCodeCityRow?.indexOf(' ')
        )
        dispatch({
          type: 'Results',
          payload: {
            FullName: OCRResult?.find(x => x.wFieldType === 25)?.Field_Visual,
            NRIC: OCRResult?.find(x => x.wFieldType === 2)?.Field_Visual,
            Address: Address,
            City: City,
            State: State,
            Country: OCRResult?.find(x => x.wFieldType === 38)?.Field_Visual,
            PostCode: PostCode,
          },
        })
        dispatch({
          type: 'InnoOpen',
          payload: false,
        })
      }
      dispatch({
        type: 'InnoOpen',
        payload: false,
      })
    })
  }, [innoWebCamRef])
  const handleUpload = async e => {
    let OCRResult: any = await OCRhelper(e.target.files[0])
    console.log(OCRResult)
    if (OCRResult) {
      dispatch({
        type: 'Results',
        payload: {
          FullName: OCRResult.mykadname,
          NRIC: OCRResult.mykadno,
          Address: OCRResult.mykadaddress,
          City: OCRResult.mykadcity,
          State: OCRResult.mykadstate,
          Country: 'MALAYSIA',
          PostCode: OCRResult.mykadpostcode,
        },
      })
      dispatch({
        type: 'GoogleOpen',
        payload: false,
      })
    }
  }
  const { icResults, icImage, handleBlinkID } = useBlinkID()
  const webcamRef = React.useRef(null)
  const googleWebcamRef = React.useRef(null)
  const captureIC = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()
    console.log(imageSrc, 'imageSrc')
    HuaweiOcr({
      variables: {
        File: imageSrc?.split('base64,')[1],
        // URL: 'https://iam.ap-southeast-2.myhuaweicloud.com/v3/auth/tokens',
      },
    }).then(async i => {
      if (i.data.HuaweiOCR) {
        const OCRResult = await OCRProcessing(
          'Huawei',
          i.data.HuaweiOCR?.result?.words_block_list
            ?.map(x => x.words)
            .join('\n')
        )

        dispatch({
          type: 'Results',
          payload: {
            FullName: OCRResult.mykadname,
            NRIC: OCRResult.mykadno,
            Address: OCRResult.mykadaddress,
            City: OCRResult.mykadcity,
            State: OCRResult.mykadstate,
            Country: 'Malaysia',
            PostCode: OCRResult.mykadpostcode,
          },
        })
        dispatch({
          type: 'HuaweiOpen',
          payload: false,
        })
      }
    })
  }, [webcamRef])
  const uploadGoogleID = () => {
    inputFile?.current.click()
  }
  const uploadID = () => {
    huaweiInputFile.current.click()
  }
  const uploadInnoID = () => {
    innoInputFile.current.click()
  }
  const captureGoogleIC = React.useCallback(async () => {
    const imageSrc = googleWebcamRef.current.getScreenshot()
    console.log(imageSrc, 'imageSrc')
    fetch(imageSrc)
      .then(res => {
        return res.arrayBuffer()
      })
      .then(async buf => {
        dispatch({ type: 'Scanning', payload: true })

        let OCRResult: any = await OCRhelper(
          new File([buf], 'FullName', { type: 'image/png' })
        )
        dispatch({
          type: 'Results',
          payload: {
            FullName: OCRResult.mykadname,
            NRIC: OCRResult.mykadno,
            Address: OCRResult.mykadaddress,
            City: OCRResult.mykadcity,
            State: OCRResult.mykadstate,
            Country: 'MALAYSIA',
            PostCode: OCRResult.mykadpostcode,
          },
        })
        dispatch({
          type: 'GoogleOpen',
          payload: false,
        })
      })
      .catch(err => {
        console.error(err)
        dispatch({
          type: 'GoogleOpen',
          payload: false,
        })
      })
  }, [webcamRef])

  useEffect(() => {
    if (icResults) {
      dispatch({
        type: 'Open',
        payload: false,
      })
      dispatch({
        type: 'Results',
        payload: {
          FullName: icResults.FullName,
          NRIC: icResults.NRIC,
          Address: icResults.Address,
          City: icResults.City,
          State: icResults.State,
          Country: icResults.Country,
          PostCode: icResults.PostCode,
        },
      })
    }
  }, [icResults])

  return (
    <>
      <ContentWrapper>
        <Box
          display={state.Open ? 'unset' : 'none'}
          width="100%"
          height="100%"
          boxSizing="border-box"
          bgcolor="#00000080"
        >
          <Box id="screen-scanning" position="relative">
            <video
              id="camera-feed"
              playsInline
              style={{
                height: '100%',
                width: '100%',
                // position: 'fixed',
                right: 0,
                bottom: 0,
                top: 0,
                objectFit: 'cover',
              }}
            ></video>
            <canvas
              id="camera-feedback"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
              }}
            ></canvas>
            <Box id="screen-scanning" display="none">
              <img id="target-image" alt="hidden" />
            </Box>
            <Box
              component="p"
              id="camera-guides"
              position="absolute"
              right={0}
              left={0}
              top={0}
              bottom={0}
              textAlign="center"
              color="#fff"
            >
              Position ID in the frame to perform capturing
            </Box>

            <Box
              width="100%"
              zIndex={100}
              position="fixed"
              bottom="32px"
              right="0"
              bgcolor="#00000080"
              padding="50px 0px"
            >
              <Box
                display="flex"
                width="100%"
                margin="0 auto"
                justifyContent="space-evenly"
              >
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    dispatch({ type: 'Open', payload: false })
                  }}
                >
                  <CloseIcon style={{ color: '#fff', fontSize: '30px' }} />
                </IconButton>
                {/* <IconButton
                  aria-label="delete"
                  onClick={() => inputFile.current.click()}
                >
                  <InsertPhotoIcon style={{ color: '#fff', fontSize: '30px' }} />
                </IconButton> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={state.Open ? 'none' : 'unset'}>
          <input
            accept="image/*"
            id="image-file"
            hidden
            onChange={e => handleUpload(e)}
            type="file"
            ref={inputFile}
          />
          <input
            accept="image/*"
            id="image-file-huawei"
            hidden
            onChange={e => handleHuaweiInputFile(e)}
            type="file"
            ref={huaweiInputFile}
          />
          <input
            accept="image/*"
            id="image-file-inno"
            hidden
            onChange={e => handleInnoUpload(e)}
            type="file"
            ref={innoInputFile}
          />
          <List className="core-list">
            {AboutUsList?.map((el, i) => (
              <ListItem key={i} onClick={el.onClick}>
                <ListItemAvatar>
                  <Avatar className="svg-icon" src={el.Icon} />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle fw-500 flex-space">
                        {el.Title}
                      </span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={e => {}}>
                    <LeftArrow
                      style={{
                        transform: 'rotate(180deg)',
                      }}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <StyledCardContent>
            <>
              <Box mb="4px">
                <Box className="xsTitle color-text-2" mb="4px" display="flex">
                  {`Full Name: `}
                  {``}
                </Box>
                <Box className="xsTitle color-text-1" mb="4px" display="flex">
                  {state.Results?.FullName}
                </Box>
              </Box>
              <Box mb="4px">
                <Box className="xsTitle color-text-2" mb="4px" display="flex">
                  {`IC: `}
                  {``}
                </Box>
                <Box className="xsTitle color-text-1" mb="4px" display="flex">
                  {state.Results?.NRIC}
                </Box>
              </Box>
              <Box mb="4px">
                <Box className="xsTitle color-text-2" mb="4px" display="flex">
                  {`Address: `}
                  {``}
                </Box>
                <Box className="xsTitle color-text-1" mb="4px" display="flex">
                  {state.Results?.Address}
                </Box>
              </Box>
              <Box mb="4px">
                <Box className="xsTitle color-text-2" mb="4px" display="flex">
                  {`City: `}
                  {``}
                </Box>
                <Box className="xsTitle color-text-1" mb="4px" display="flex">
                  {state.Results?.City}
                </Box>
              </Box>
              <Box mb="4px">
                <Box className="xsTitle color-text-2" mb="4px" display="flex">
                  {`State: `}
                  {``}
                </Box>
                <Box className="xsTitle color-text-1" mb="4px" display="flex">
                  {state.Results?.State}
                </Box>
              </Box>
              <Box mb="4px">
                <Box className="xsTitle color-text-2" mb="4px" display="flex">
                  {`Country: `}
                  {``}
                </Box>
                <Box className="xsTitle color-text-1" mb="4px" display="flex">
                  {state.Results?.Country}
                </Box>
                <Box mb="4px">
                  <Box className="xsTitle color-text-2" mb="4px" display="flex">
                    {`PostCode: `}
                    {``}
                  </Box>
                  <Box className="xsTitle color-text-1" mb="4px" display="flex">
                    {state.Results?.PostCode}
                  </Box>
                </Box>
                {/* {state.Randoms?.map(x => (
                  <Box
                    className="xsTitle color-text-1 text-noflow"
                    mb="4px"
                    display="flex"
                  >
                    {x.words}
                  </Box>
                ))} */}
              </Box>
            </>
          </StyledCardContent>
        </Box>
      </ContentWrapper>
      <GoogleScanIDModal
        open={state.GoogleOpen}
        webcamRef={googleWebcamRef}
        captureID={captureGoogleIC}
        uploadID={uploadGoogleID}
        onClose={() => dispatch({ type: 'GoogleOpen', payload: false })}
      />
      <ScanIDModal
        open={state.HuaweiOpen}
        webcamRef={webcamRef}
        captureID={captureIC}
        uploadID={uploadID}
        onClose={() => dispatch({ type: 'HuaweiOpen', payload: false })}
      />
      <InnoScanIDModal
        open={state.InnoOpen}
        webcamRef={innoWebCamRef}
        captureID={captureInnoIC}
        uploadID={uploadInnoID}
        onClose={() => dispatch({ type: 'InnoOpen', payload: false })}
      />
    </>
  )
}
